import React, { useState, useEffect, useRef } from "react";
import AdminPanel from "./adminPanel";
import WeeklyDateRanges from "./weeklydaterange";
import axios from "axios";

const AdminSetMenu = () => {
  const [calRange, setCalRange] = useState(52);
  const [mealsLinks, setMealsLinks] = useState({});
  const [dateused, setDateused] = useState("");
  const [titlesArray, setTitlesArray] = useState([]);
  const [secondListIds, setSecondListIds] = useState([]);
  const [linksArray, setLinksArray] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [loader, setLoader] = useState(false);
  const [lastMonday, setLastMonday] = useState(null);

  const handleLastMonday = (date) => {
    const splitDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!splitDateRegex.test(date)) {
      date = date.toISOString().split("T")[0];
    }
    console.log("date", date);
    // const localDate = new Date(
    //   date.getTime() - date.getTimezoneOffset() * 60000
    // );
    //console.log("localDate", localDate);
    setLastMonday(date); // Store the adjusted date
  };

  const handleSelectedIndexChange = (index) => {
    setSelectedIndex(index);
    console.log("selectedIndex", selectedIndex);
  };

  useEffect(() => {
    fetchAllMeals();
  }, []);

  useEffect(() => {
    console.log("lastMonday", lastMonday);
    if (lastMonday) {
      fetchMeals(lastMonday);
    }
  }, [lastMonday]);

  const fetchAllMeals = async () => {
    try {
      const res = await axios.get("/api/auth/allmeals");
      setTitlesArray(res.data);
      console.log("titlesArray", titlesArray);
    } catch (error) {
      console.error(error);
    }
  };

  const [linksData, setLinksData] = useState([]);

  useEffect(() => {
    console.log("titlesArray", titlesArray);
  }, [titlesArray]);

  const fetchMeals = async (dateused) => {
    try {
      const response = await axios.get("/api/auth/mealLinks", {
        params: { dateUsed: dateused },
      });

      setSecondListIds((prevState) => {
        const newEntries = response.data.filter((item) => {
          return !prevState.some(
            (entry) => entry.id === item.id && entry.dateUsed === item.dateUsed
          );
        });

        return [
          ...prevState,
          ...newEntries.map((item) => ({
            id: item.id,
            dateUsed: item.dateUsed,
          })),
        ];
      });
    } catch (error) {
      console.error(error);
    }
  };

  const firstListItems = titlesArray;
  const previousSecondListItems = useRef([]);
  const [secondListItems, setSecondListItems] = useState([]);

  useEffect(() => {
    console.log("secondListIds", secondListIds, "lastMonday", lastMonday);
    const matchedItems = titlesArray.filter((item) =>
      secondListIds.some(
        (listId) => listId.id === item._id && listId.dateUsed === lastMonday
      )
    );
    console.log("matchedItems", matchedItems);

    setSecondListItems(matchedItems);
  }, [secondListIds, titlesArray, lastMonday]);

  const moveToSecondList = (id) => {
    const newEntry = { id, dateUsed: lastMonday };

    // Check if the new entry already exists in secondListIds
    const entryExists = secondListIds.some(
      (entry) =>
        entry.id === newEntry.id && entry.dateUsed === newEntry.dateUsed
    );

    if (!entryExists) {
      setSecondListIds((prevData) => [...prevData, newEntry]);
      console.log("setSecondListIds", secondListIds);
    } else {
      console.log("Entry already exists in secondListIds.");
    }
  };

  const moveToFirstList = (id) => {
    setSecondListIds((prevData) => prevData.filter((entry) => entry.id !== id));
    console.log("secondListIds after removal", secondListIds);
  };

  const handleSave = async () => {
    setLoader(true);
    try {
      await axios.post("api/auth/deleteLinks", {}).then(() => {
        saveLinks();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const saveLinks = async () => {
    console.log("linksData", secondListIds);
    try {
      await axios.post("api/auth/setmenu", secondListIds);
      setLoader(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="adminDash">
      <h1>Set Menu</h1>
      <AdminPanel />
      <div className="adminMainContent">
        <div className="twocolGrid">
          <div className="themeals">
            <h3>Click to add meal to date</h3>
            {firstListItems
              .sort((a, b) =>
                a.title.toLowerCase().localeCompare(b.title.toLowerCase())
              )
              .map((themeal) => (
                <div
                  key={themeal._id}
                  className={themeal.classic ? "themeal classic" : "themeal"}
                  onClick={() => moveToSecondList(themeal._id)}
                >
                  {themeal.title}
                </div>
              ))}
          </div>
          <div className="weekRange">
            <WeeklyDateRanges
              onLastMonday={handleLastMonday}
              calRange={calRange}
              onRequest={fetchMeals}
              onSelectedIndexChange={handleSelectedIndexChange}
            />
          </div>
          <div className="menuItems">
            <h3>Click to remove meal from date</h3>
            {secondListItems
              .sort((a, b) =>
                a.title.toLowerCase().localeCompare(b.title.toLowerCase())
              )
              .map((match, index) => (
                <div
                  className={match.classic ? "classicP classic" : "currentP"}
                  key={match._id}
                  onClick={() => moveToFirstList(match._id)}
                >
                  <div className="current" key={index}>
                    {match.title}
                  </div>
                </div>
              ))}
          </div>
          <button onClick={handleSave}>{loader ? "Saving..." : "Save"}</button>
        </div>
      </div>
    </div>
  );
};

export default AdminSetMenu;
