import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AdminPanel from "./adminPanel";
import WhatsAppLink from "./WhatsAppLink";

const UsersList = () => {
  const [user, setUser] = useState({});
  const [email, setEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [individualMailForm, setIndividualMailForm] = useState("");
  const [groupMailForm, setGroupMailForm] = useState("");
  const [subject, setSubject] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [groupName, setGroupName] = useState("All Users");
  const deleteref = useRef();
  const individualEmailRef = useRef();
  const groupEmailRef = useRef();

  useEffect(() => {
    document.title = "Users List";
    const fetchUsers = async () => {
      try {
        const res = await axios.get("/api/auth/allusers");
        setUsers(res.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  const deleteUser = async (id) => {
    try {
      await axios.delete(`/api/auth/deleteuser/${id}`);
      setUsers((prevUsers) => prevUsers.filter((user) => user._id !== id));
      setMessage("User deleted successfully");
    } catch (err) {
      console.error("Error deleting user:", err);
      setMessage("Failed to delete user");
    }
  };

  const sendIndividualMail = async (event) => {
    event.preventDefault();
    try {
      await axios.post("/api/auth/sendemails", {
        emailList,
        subject,
        message: individualMailForm,
      });
      setMessage("Email sent successfully");
    } catch (err) {
      console.error("Error sending email:", err);
      setMessage("Failed to send email");
    }
  };

  const sendGroupMail = async (event) => {
    event.preventDefault();
    try {
      await axios.post("/api/auth/sendemails", {
        emailList: users.map((user) => user.email),
        subject,
        message: groupMailForm,
      });
      setMessage("Emails sent successfully");
    } catch (err) {
      console.error("Error sending emails:", err);
      setMessage("Failed to send emails");
    }
  };

  const toggleDialog = (dialogRef) => {
    if (!dialogRef.current) return;
    dialogRef.current.hasAttribute("open")
      ? dialogRef.current.close()
      : dialogRef.current.showModal();
  };

  const openDeleteDialog = (id) => {
    setUser(id);
    toggleDialog(deleteref);
  };

  const openIndividualEmailDialog = (email, firstName) => {
    setEmailList([email]);
    setIndividualMailForm(`Dear ${firstName},`);
    setSubject("");
    toggleDialog(individualEmailRef);
  };

  const openGroupEmailDialog = () => {
    setEmailList(users.map((user) => user.email));
    setGroupMailForm("");
    setSubject("");
    toggleDialog(groupEmailRef);
  };

  return (
    <>
      <div className="adminDash">
        <h1>Users List</h1>
        <AdminPanel />
        <div className="adminMainContent userList">
          <div className="userTable">
            <button className="emailAll" onClick={openGroupEmailDialog}>
              Email All Users
            </button>
            <table>
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>WhatsApp</th>
                  <th>Curent credit</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user._id}>
                    <td>
                      {user.firstName} {user.lastName}
                    </td>
                    <td
                      className="emailLink"
                      onClick={() =>
                        openIndividualEmailDialog(user.email, user.firstName)
                      }
                    >
                      {user.email}
                    </td>
                    <td>{user.contact}</td>
                    <td>
                      <WhatsAppLink phoneNumber={user.contact} />
                    </td>
                    <td>{user.credit}</td>
                    <td>
                      <button onClick={() => openDeleteDialog(user._id)}>
                        Delete User
                      </button>
                      <Link to={`/edituser?id=${user._id}`}>
                        <button>View User</button>
                      </Link>
                      <button
                        onClick={() =>
                          openIndividualEmailDialog(user.email, user.firstName)
                        }
                      >
                        Email User
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <dialog className="filter" ref={deleteref} id="deleteref">
        <div className="modalTop">
          Delete User
          <button className="close" onClick={() => toggleDialog(deleteref)}>
            X
          </button>
        </div>
        <div className="modalBody">
          <div className="bfDelete">
            <p>Are you sure you want to delete this user?</p>
            <button onClick={() => toggleDialog(deleteref)}>Cancel</button>
            <button onClick={() => deleteUser(user._id)}>Delete</button>
          </div>
          <div className="afDelete">
            {message}
            <button onClick={() => toggleDialog(deleteref)}>Close</button>
          </div>
        </div>
      </dialog>

      <dialog
        className="filter"
        ref={individualEmailRef}
        id="individualEmailRef"
      >
        <div className="modalTop">
          Email User
          <button
            className="close"
            onClick={() => toggleDialog(individualEmailRef)}
          >
            X
          </button>
        </div>
        <div className="modalBody">
          <div className="emailForm">
            <p>To: {emailList}</p>
            <input
              type="text"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <textarea
              name="sendMessage"
              id="sendMessage"
              cols="30"
              rows="10"
              value={individualMailForm}
              onChange={(e) => setIndividualMailForm(e.target.value)}
            ></textarea>
            <button onClick={() => toggleDialog(individualEmailRef)}>
              Cancel
            </button>
            <button onClick={sendIndividualMail}>Send</button>
          </div>
          {message && <p>{message}</p>}
        </div>
      </dialog>

      <dialog className="filter" ref={groupEmailRef} id="groupEmailRef">
        <div className="modalTop">
          Email All Users
          <button className="close" onClick={() => toggleDialog(groupEmailRef)}>
            X
          </button>
        </div>
        <div className="modalBody">
          <div className="emailForm">
            <p>To: {groupName}</p>
            <input
              type="text"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <textarea
              name="sendMessage"
              id="sendMessage"
              cols="30"
              rows="10"
              value={groupMailForm}
              onChange={(e) => setGroupMailForm(e.target.value)}
            ></textarea>
            <button onClick={() => toggleDialog(groupEmailRef)}>Cancel</button>
            <button onClick={sendGroupMail}>Send</button>
          </div>
          {message && <p>{message}</p>}
        </div>
      </dialog>
    </>
  );
};

export default UsersList;
