import axios from "axios";
import { useState, useEffect } from "react";
import AdminPanel from "./adminPanel";
import * as XLSX from "xlsx";

const Deliveries = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    document.title = "Deliveries";
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get("/api/auth/admindel");
        setOrders(response.data.orders);
        console.log("Orders:", response.data.orders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  const DelSlotFunc = ({ userSlot }) => {
    userSlot = parseInt(userSlot);
    switch (userSlot) {
      case 1:
        return "5 - 7am";
      case 2:
        return "7 - 9am";
      case 3:
        return "5 - 7pm";
      case 4:
        return "7 - 9pm";
      default:
        return "Unknown time";
    }
  };

  const groupOrdersByWeekStarting = (orders) => {
    const groupedOrders = {};
    orders.forEach((order) => {
      order.mealsByStartDate.forEach((mealEntry) => {
        if (!groupedOrders[mealEntry.weekstarting]) {
          groupedOrders[mealEntry.weekstarting] = [];
        }
        groupedOrders[mealEntry.weekstarting].push({
          ...order,
          mealEntry,
        });
      });
    });
    return groupedOrders;
  };

  const groupOrdersByDeliveryDay = (orders) => {
    const groupedOrders = {};
    orders.forEach((order) => {
      order.mealsByStartDate.forEach((mealEntry) => {
        if (!groupedOrders[mealEntry.deliveryDay]) {
          groupedOrders[mealEntry.deliveryDay] = [];
        }
        groupedOrders[mealEntry.deliveryDay].push({
          ...order,
          mealEntry,
        });
      });
    });
    return groupedOrders;
  };

  const downloadExcelForWeekStarting = (weekStarting) => {
    const groupedOrdersByWeek = groupOrdersByWeekStarting(orders);
    const weekOrders = groupedOrdersByWeek[weekStarting];

    if (weekOrders && weekOrders.length > 0) {
      const worksheetData = [
        [
          "Order ID",
          "Customer Name",
          "Delivery Date",
          "User Address",
          "Time Slot",
          "Servings",
          "Meal Selections",
        ],
        ...weekOrders.map((entry) => [
          entry._id,
          `${entry.userId.firstName} ${entry.userId.lastName}`,
          entry.mealEntry.deliveryDate,
          `${entry.userId.addressLine1}, ${entry.userId.street}, ${entry.userId.town}, ${entry.userId.area}`,
          DelSlotFunc({ userSlot: entry.mealEntry.deliverTimeSlot }),
          entry.mealEntry.servings,
          entry.mealEntry.mealSelections
            .map((mealSelection) => mealSelection.meal.title)
            .join(", "),
        ]),
      ];

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `Orders_${weekStarting}`
      );

      XLSX.writeFile(workbook, `Orders_${weekStarting}.xlsx`);
    }
  };

  const renderOrders = () => {
    const groupedOrdersByWeek = groupOrdersByWeekStarting(orders);
    const orderElements = [];

    for (const weekStarting in groupedOrdersByWeek) {
      orderElements.push(
        <div key={weekStarting}>
          <h2>Week Starting: {weekStarting}</h2>
          <button onClick={() => downloadExcelForWeekStarting(weekStarting)}>
            Download
          </button>
          {Object.entries(
            groupOrdersByDeliveryDay(groupedOrdersByWeek[weekStarting])
          ).map(([deliveryDay, dayOrders], index) => (
            <div key={index}>
              <h3>Delivery Day: {deliveryDay}</h3>
              <table className="orderTable">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Customer Name</th>
                    <th>Delivery Date</th>
                    <th>User Address</th>
                    <th>Time Slot</th>
                    <th>Servings</th>
                    <th>Meal Selections</th>
                  </tr>
                </thead>
                <tbody>
                  {dayOrders.map((entry, subIndex) => (
                    <tr key={subIndex} className="order-entry">
                      <td>{entry._id}</td>
                      <td>
                        {entry.userId.firstName} {entry.userId.lastName}
                      </td>
                      <td>{entry.mealEntry.deliveryDate}</td>
                      <td className="address">
                        {entry.userId.addressLine1}, {entry.userId.street},{" "}
                        {entry.userId.town}, {entry.userId.area}
                      </td>
                      <td>
                        {DelSlotFunc({
                          userSlot: entry.mealEntry.deliverTimeSlot,
                        })}
                      </td>
                      <td>{entry.mealEntry.servings}</td>
                      <td>
                        {entry.mealEntry.mealSelections.map(
                          (mealSelection, mealIndex) => (
                            <div key={mealIndex} className="meal-selection">
                              <p>
                                <strong>Meal:</strong>{" "}
                                {mealSelection.meal.title}
                              </p>
                              <p>
                                <strong>Number of Servings:</strong>{" "}
                                {mealSelection.count}
                              </p>
                            </div>
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      );
    }

    return orderElements;
  };

  return (
    <div className="adminDash">
      <h1>Deliveries</h1>
      <AdminPanel />
      <div className="adminMainContent">
        <div className="dashboard">
          <div>
            <p>{orders.length} orders</p>
            <div>{renderOrders()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deliveries;
