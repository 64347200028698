import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import axios from "axios";

import { AuthContext } from "../context/AuthContext";
import UserPanel from "./userPanel";

const Accountinfo = (props) => {
  const { authState } = useContext(AuthContext);

  // useEffect(() => {
  //     props.messageSet('accountinfo')
  // })
  const [firstName, setfirstName] = useState(authState.user.firstName);
  const [lastName, setlastName] = useState(authState.user.lastName);
  const [contact, setcontact] = useState(authState.user.contact);
  const [email, setemail] = useState(authState.user.email);
  const [addressLine1, setaddressLine1] = useState(authState.user.addressLine1);
  const [addressLine2, setaddressLine2] = useState(authState.user.addressLine2);
  const [street, setstreet] = useState(authState.user.street);
  const [area, setarea] = useState(authState.user.area);
  const [town, settown] = useState(authState.user.town);

  const [ccNumber, setccNumber] = useState("");
  const [ccName, setccName] = useState("");
  const [ccCVV, setccCVV] = useState("");
  const [ccExpiryDate, setCcexp] = useState("");

  const [ccnoerrorMessage, setccnoerrorMessage] = useState("");
  const [ccNameerrorMessage, setccNameerrorMessage] = useState("");
  const [ccCVVerrorMessage, setccCVVerrorMessage] = useState("");
  const [ccexperrorMessage, setccexperrorMessage] = useState("");
  const [formdata, setFormdata] = useState({});

  const userId = authState.user._id;

  const cities = [
    "Abu Dhabi",
    "Ajmān",
    "Al Ain",
    "Al Awdah",
    "Al Fahlayn",
    "Al Fulayyah",
    "Al Fara'",
    "Al Ghabah",
    "Al Ghabam",
    "Al Ghashbah",
    "Al Hamraniyah",
    "Al Hamriyah",
    "Al Haybah",
    "Al Hayl",
    "Al Hayr",
    "Al Hayrah",
    "Al Hulaylah",
    "Al Jaddah",
    "Al Khari",
    "Al Khashfah",
    "Al Mahamm",
    "Al Masafirah",
    "Al Mataf",
    "Al Mu'ammarah",
    "Al Naslah",
    "Al Qir",
    "Al Quwayz",
    "Al Usayli",
    "Al Yahar",
    "Ar Rafa'ah",
    "Arthaban",
    "Athabat",
    "Ash Sha'm",
    "As Sur",
    "Awanat, Ras al-Khaimah",
    "Baqal",
    "Bidiyah",
    "Daftah",
    "Dhadna",
    "Dibba Al-Fujairah",
    "Dibba Al-Hisn",
    "Dubai",
    "Fujairah",
    "Kalba",
    "Kawr Fakkān",
    "Mīnā' Jabal 'Alī",
    "Mīnā' Şaqr",
    "Mīnā' Zāyid",
    "Ras al-Khaimah",
    "Umm al-Qaiwain",
    "Quṭūf",
    "Ruwais",
    "Sharjah",
    "Sila",
  ];

  const SelectCities = () => {
    return (
      <>
        <select name="town" defaultValue={authState.user.town}>
          <option value="">Select City</option>
          {cities?.map((item) => {
            return (
              <option value={item} key={item}>
                {item}
              </option>
            );
          })}
        </select>
        <div className="error"></div>
      </>
    );
  };

  const handleedit = (e) => {
    let divE = e.currentTarget.closest(".uneditable");
    let divU = e.currentTarget.parentNode.nextElementSibling;

    divE.classList.toggle("hide");
    divU.classList.toggle("hide");
  };

  const handleSave = async (e) => {
    let divE = e.currentTarget.closest(".editable");
    let divU = e.currentTarget.parentNode.parentNode.previousSibling;
    let formdataS = divE.parentNode.querySelector(".form");
    let data = new FormData(formdataS);
    setFormdata(data);

    let formObj = Object.fromEntries(data);

    divE.classList.toggle("hide");
    divU.classList.toggle("hide");

    try {
      const response = await axios
        .put(`/api/auth/updateUser/${userId}`, {
          formObj,
        })
        .then((response) => {
          initialValues.contact = response.data.contact;
        });
    } catch (error) {
      console.error(`Error updating options selections:`, error);
    }
  };

  const cancel = (e) => {
    let divE = e.currentTarget.closest(".editable");
    let divU = e.currentTarget.parentNode.parentNode.previousSibling;
    divE.classList.toggle("hide");
    divU.classList.toggle("hide");
  };

  const initialValues = {
    firstName: authState.user.firstName,
    lastName: authState.user.lastName,
    email: authState.user.email,
    contact: authState.user.contact,
    addressLine1: authState.user.addressLine1,
    addressLine2: authState.user.addressLine2,
    street: authState.user.street,
    area: authState.user.area,
    town: authState.user.town,
  };
  const [values, setValues] = useState(initialValues);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    console.log(values);
  };

  const newaddressLine2 = values.addressLine2
    ? `${authState.user.addressLine2}<br />`
    : "";
  const addressHtml = `${newaddressLine2}`;

  return (
    <>
      <div className="dashboardContainer">
        <div className="userContainer">
          <div className="userData">
            <div className="container">
              <div className="headerSection">
                <h1>Account Information</h1>
                <h2>Set your account information</h2>
                <Link to={"/dashboard"}>
                  <button className="btd">Back to dashboard</button>
                </Link>
              </div>
              <div className="dashboardPanel">
                <h3>Your Information</h3>

                <div className="infoContainer accinfo">
                  <div className="uneditable">
                    <ul>
                      <li>
                        Name:
                        <span>
                          {values.firstName} {values.lastName}
                        </span>
                      </li>
                      <li>
                        Email:<span> {values.email}</span>
                      </li>
                      <li>
                        Phone:<span> {values.contact}</span>
                      </li>
                    </ul>

                    <button onClick={handleedit}>Edit</button>
                  </div>
                  <div className="editable hide signin">
                    <form className="form">
                      <label>Name:</label>
                      <input
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                      />
                      <input
                        name="lastName"
                        type="text"
                        value={values.lastName}
                        onChange={handleChange}
                      />
                      <label>Email:</label>
                      <input
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <label>Contact number:</label>
                      <input
                        type="text"
                        name="contact"
                        value={values.contact}
                        onChange={handleChange}
                      />
                    </form>
                    <div className="actions">
                      <button onClick={cancel}>Cancel</button>
                      <button onClick={handleSave}>Save</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dashboardPanel">
                <h3>Delivery Information</h3>
                <div className="deliveryContainer">
                  <div className="uneditable">
                    <p>
                      <span>
                        {values.addressLine1}
                        <br />
                        <div
                          dangerouslySetInnerHTML={{ __html: addressHtml }}
                        />
                        {values.street}
                        <br />
                        {values.area}
                        <br />
                        {values.town}
                      </span>
                    </p>
                    <button onClick={handleedit}>Edit</button>
                  </div>
                  <div className="editable hide signin">
                    <form className="form">
                      <input
                        type="text"
                        name="addressLine1"
                        value={values.addressLine1}
                        onChange={handleChange}
                      />
                      <input
                        type="text"
                        name="addressLine2"
                        value={values.addressLine2}
                        onChange={handleChange}
                      />
                      <input
                        type="text"
                        name="street"
                        value={values.street}
                        onChange={handleChange}
                      />
                      <input
                        type="text"
                        name="area"
                        value={values.area}
                        onChange={handleChange}
                      />
                      <div>
                        <SelectCities />
                      </div>
                    </form>
                    <div className="actions">
                      <button onClick={cancel}>Cancel</button>
                      <button onClick={handleSave}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accountinfo;
