import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useState, useContext, useRef, useCallback } from "react";
import axios from "axios";
import WeeklyDateRanges from "../components/weeklydaterange";
import { AuthContext } from "../context/AuthContext";
import "../styles/meals.css";
import Loading from "./loading";
import MealModal from "./mealModal";
import Modal from "./modal";
import calculateUpcomingDay from "./deliverydays";

const MyMeals = () => {
  const [loading, setLoading] = useState(false);
  const { authState } = useContext(AuthContext);
  const [datelast, setDatelast] = useState("");
  const [pricepermeal, setPricepermeal] = useState(50);
  const [gourmetPrice, setGourmetPrice] = useState(10);
  const [people, setPeople] = useState(authState.user.noPeople);
  const [nmeals, setNmeals] = useState(authState.user.noMeals);
  const [credit, setCredit] = useState(0);
  const [runningCredit, setRunningCredit] = useState(0);
  const [mealsCountState, setmealsCountState] = useState([0, 0, 0, 0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [originalCredit, setOriginalCredit] = useState(authState.user.credit);
  const location = useLocation();
  const [MealsList, setMealsList] = useState([]);
  const [keys, setKeys] = useState([]);
  const [mealsCount, setMealsCount] = useState(0);
  const [mealscountpw, setmealscountpw] = useState(0);
  const [filteredMealsList, setFilteredMealsList] = useState([]);
  const preferences = authState.user.mealPreferences;
  const targetDay = authState.user.deliveryDay.toLowerCase();

  // let meals = authState.user.noMeals;
  const [values, setValues] = useState([]);
  const userId = authState.user._id;
  const [mealSelections, setMealSelections] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [dateused, setDateused] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedMeal, setSelectedMeal] = useState(null); // Change state to store selected meal object
  const [mealSelectionsByDate, setMealSelectionsByDate] = useState({});
  const [currentSelections, setCurrentSelections] = useState([]);
  const [mealCounts, setMealCounts] = useState({});
  const [mostRecentMonday, setmostRecentMonday] = useState("");
  const [allOrderData, setAllOrderData] = useState([]);
  const [totalCountOrg, setTotalCountOrg] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [weekCount, setWeekCount] = useState(0); //weekCount
  const [orderCost, setOrderCost] = useState(0);
  const [originalOrderCost, setOriginalOrderCost] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [originaltotalOrderCost, setOriginaltotalOrderCost] = useState(0);
  const [orderDifference, setOrderDifference] = useState(0);
  let [open, setOpen] = useState(false);
  let [checkoutOpen, setCheckoutOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const [newBalance, setNewBalance] = useState(0);

  const [mergedMealsData, setMergedMealsData] = useState({});
  const [clientSecret, setClientSecret] = useState(null);
  const [amount, setAmount] = useState(0);
  const [firstDD, setFirstDD] = useState("");
  const [disableClicks, setDisableClicks] = useState(false);
  const [deliveryDay, setDeliveryDay] = useState(
    authState.user.deliveryDay || ""
  );
  const [checked, setChecked] = useState(true);
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [city, setCity] = useState("");
  const [streetMessage, setStreetMessage] = useState("");
  const [street, setStreet] = useState("");
  const [add1Message, setAdd1Message] = useState("");
  const [add2Message, setAdd2Message] = useState("");
  const [cityMessage, setCityMessage] = useState("");
  const [rangeStart, setRangeStart] = useState("");

  const filters = [
    "family friendly",
    "kid friendly",
    "low carb",
    "vegetarian",
    "pescatarian",
    "calorie smart",
    "classics",
    "guilt free",
    "chicken",
    "meat",
    "gourmet",
    "breakfast",
    "clear filter",
  ];

  const [selectedDate, setSelectedDate] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [index, setIndex] = useState(0);
  const buttonsRef = useRef([]);
  const [firstDeliveryDate, setFirstDeliveryDate] = useState(null);
  const [transformedSelections, setTransformedSelections] = useState([]);
  const [newOrderObject, setNewOrderObject] = useState({});

  const counts = {};

  const navigate = useNavigate();
  const [isCheckoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const checkoutDialogRef = useRef(null);
  const updateDialogRef = useRef(null);
  const [smallimgs1, setSmallimgs1] = useState([]);
  const [smallimgs2, setSmallimgs2] = useState([]);
  const [smallimgs3, setSmallimgs3] = useState([]);
  const [smallimgs4, setSmallimgs4] = useState([]);
  const [smallimgs, setSmallimgs] = useState(smallimgs1);
  useEffect(() => {
    document.title = "My Meals";
  }, []);

  const [lastMonday, setLastMonday] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [weeekmealsTotal, setWeekmealsTotal] = useState(0);
  const [DbOrderTotals, setDbOrderTotals] = useState(0);
  const [DbOrderTotal, setDbOrderTotal] = useState(0);
  const [totalorder, setTotalorder] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [isodate, setIsodate] = useState("");
  //  smallimgs variables
  // const smallimgs1 = [];
  // const smallimgs2 = [];
  // const smallimgs3 = [];
  // const smallimgs4 = [];

  // Array of smallimgs
  // const smallimgsArray = [smallimgs1, smallimgs2, smallimgs3, smallimgs4];
  // const [smallimgs, setSmallimgs] = useState(smallimgsArray[0]);
  const handleIsoDate = (date) => {
    setIsodate(date);
  };

  const handleLastMonday = (date) => {
    const splitDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!splitDateRegex.test(date)) {
      date = date.toISOString().split("T")[0];
    }

    setLastMonday(date); // Store the adjusted date
  };
  const [runningTotal, setRunningTotal] = useState(0);
  const handleSelectedIndexChange = (index) => {
    setSelectedIndex(index);
  };

  const fetchMeals = async (isoDate, index) => {
    console.log("smallimgs3", smallimgs3);
    setSmallimgs([]);
    setSmallimgs1([]);
    setSmallimgs2([]);
    setSmallimgs3([]);
    setSmallimgs4([]);
    setLoading(true);
    //console.log("mostRecentMonday", isoDate);
    setDatelast(isoDate);
    try {
      const response = await axios
        .get(`/api/auth/newMeals`, {
          params: {
            dateUsed: isoDate,
          },
        })
        .then((res) => {
          const mealsData = res.data.mergedResults;
          const prefLength = preferences.length;

          if (res) {
            setErrorMessage("");
          }

          // console.log("Meals Data:", mealsData); // Debugging log

          switch (prefLength) {
            case 1:
              mealsData.sort((a, b) =>
                a.mealtype &&
                a.mealtype[0] &&
                a.mealtype[0].includes(preferences[0])
                  ? -1
                  : 1
              );
              break;
            case 2:
              mealsData.sort((a, b) =>
                a.mealtype &&
                a.mealtype[0] &&
                (a.mealtype[0].includes(preferences[0]) ||
                  a.mealtype[0].includes(preferences[1]))
                  ? -1
                  : 1
              );
              break;
            case 3:
              mealsData.sort((a, b) =>
                a.mealtype &&
                a.mealtype[0] &&
                (a.mealtype[0].includes(preferences[0]) ||
                  a.mealtype[0].includes(preferences[1]) ||
                  a.mealtype[0].includes(preferences[2]))
                  ? -1
                  : 1
              );
              break;
            case 4:
              mealsData.sort((a, b) =>
                a.mealtype &&
                a.mealtype[0] &&
                (a.mealtype[0].includes(preferences[0]) ||
                  a.mealtype[0].includes(preferences[1]) ||
                  a.mealtype[0].includes(preferences[2]) ||
                  a.mealtype[0].includes(preferences[3]))
                  ? -1
                  : 1
              );
              break;
            case 5:
              mealsData.sort((a, b) =>
                a.mealtype &&
                a.mealtype[0] &&
                (a.mealtype[0].includes(preferences[0]) ||
                  a.mealtype[0].includes(preferences[1]) ||
                  a.mealtype[0].includes(preferences[2]) ||
                  a.mealtype[0].includes(preferences[3]) ||
                  a.mealtype[0].includes(preferences[4]))
                  ? -1
                  : 1
              );
              break;
            default:
              break;
          }
          setLoading(false);
          setMealsList(mealsData);
          setFilteredMealsList(mealsData);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessage(
        "We are currently creating a delicious menu for you to choose from. Please check back later."
      );
      return;
    }
  };

  useEffect(() => {
    const images = [];
    // console.log("currentSelections", currentSelections);
    if (MealsList.length > 0) {
      if (currentSelections[lastMonday] === undefined) {
        return;
      }

      currentSelections[lastMonday].forEach((selection) => {
        MealsList.forEach((meal) => {
          if (selection.meal === meal._id) {
            const baseName = meal.image.split(".")[0]; // Extract base name from image file name
            const ext = meal.image.split(".")[1]; // Extract extension from image file name
            images.push(`${baseName}-x.${ext}`);
          }
        });
      });
      switch (selectedIndex) {
        case 0:
          setSmallimgs1(images);
          setSmallimgs(images);
          break;
        case 1:
          setSmallimgs(images);
          setSmallimgs2(images);
          break;
        case 2:
          setSmallimgs(images);
          setSmallimgs3(images);
          break;
        case 3:
          setSmallimgs(images);
          setSmallimgs4(images);
          break;
        default:
          setSmallimgs([]);
          break;
      }
    }
  }, [MealsList]);

  useEffect(() => {
    switch (selectedIndex) {
      case 0:
        setSmallimgs(smallimgs1);
        break;
      case 1:
        setSmallimgs(smallimgs2);
        break;
      case 2:
        setSmallimgs(smallimgs3);
        break;
      case 3:
        setSmallimgs(smallimgs4);
        break;
      default:
        setSmallimgs([]);
        break;
    }
  }, [smallimgs1, smallimgs2, smallimgs3, smallimgs4]);

  // Function to update smallimgs based on selected index
  const handleSelectionChange = (index) => {
    setSelectedIndex(index);
    //setSmallimgs(smallimgsArray[index]);
  };

  useEffect(() => {
    setCurrentIndex(index);

    setMealsList([]);
    if (lastMonday) {
      fetchMeals(lastMonday, index);
      fetchOrders(lastMonday);
    }
  }, [lastMonday]);
  const fetchOrders = async (isoDate) => {
    try {
      const response = await axios.get("/api/auth/myorders", {
        params: { id: userId, dateUsed: isoDate },
      });

      const allOrderData = response.data.allOrderData;
      setTotalCountOrg(response.data.mealSelections.length);

      if (response.data.mealSelections.length === 0) {
        setDisableClicks(false);
      } else {
        const firstMeal = allOrderData[0].mealsByStartDate[0];
        // console.log("First Meal:", firstMeal.deliveryDate);
        var firstDeliveryDate = firstMeal.deliveryDate;
        setFirstDD(firstMeal.deliveryDate);

        // Parse the date in DD/MM/YYYY format
        const [day, month, year] = firstDeliveryDate.split("/");
        const deliveryDate = new Date(`${year}-${month}-${day}`);

        const now = new Date();
      }
      //console.log("currentSelections- before", currentSelections);
      setCurrentSelections((prevSelections) => {
        const selections = { ...prevSelections }; // Copy previous selections
        console.log("selections", selections);
        allOrderData.forEach((order) => {
          order.mealsByStartDate.forEach((meal) => {
            const weekStarting = meal.weekstarting;
            const newMealSelections = meal.mealSelections.map((item) => ({
              meal: item.meal,
              count: item.count,
            }));

            if (selections[weekStarting]) {
              // Merge with duplicate check
              const existingSelections = selections[weekStarting];
              newMealSelections.forEach((newMeal) => {
                const existingIndex = existingSelections.findIndex(
                  (existingMeal) => existingMeal.meal === newMeal.meal
                );
                if (existingIndex === -1) {
                  // Meal doesn't exist, add it
                  existingSelections.push(newMeal);
                } else {
                  //meal exists, update it, or do nothing.
                  existingSelections[existingIndex] = newMeal; //replace the old entry with the new.
                  //alternatively to add counts together you could do the following.
                  //existingSelections[existingIndex].count += newMeal.count;
                }
              });
              selections[weekStarting] = existingSelections;
            } else {
              // No existing selections, add all new ones
              selections[weekStarting] = newMealSelections;
            }
          });
        });
        console.log("newselections", selections);
        return selections;
      });

      //const totalCount = getTotalCount({ allOrderData });
      setDbOrderTotals(() => {
        let totalOrderSum = 0;

        allOrderData.forEach((order) => {
          order.mealsByStartDate.forEach((meal) => {
            const { ordertotal } = meal;
            totalOrderSum += ordertotal;
          });
        });

        //console.log("totalOrderSum", totalOrderSum);
        return totalOrderSum;
      });

      setValues(response.data.mealSelections);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //get the total number of meals already ordered & paid for

  useEffect(() => {
    //  console.log("TotalCountOrg", totalCountOrg);

    setTotalCount(totalCountOrg);
  }, [totalCountOrg]);

  useEffect(() => {
    // console.log("TotalCount", totalCount);
  }, [totalCount]);

  const handleChange = (e) => {
    let { name, value } = e.target;

    e.target.nextSibling.style.display = "none";

    switch (name) {
      case "add1":
        setAdd1(value);
        break;
      case "add2":
        setAdd2(value);
        break;
      case "city":
        setCity(value);
        break;

      case "street":
        setStreet(value);
        break;
    }
  };

  const toggleDialog = (id) => {
    let dialogRef;
    switch (id) {
      case "checkoutModal":
        dialogRef = checkoutDialogRef;
        setCheckoutModalOpen(!isCheckoutModalOpen);
        break;
      case "paymentModal":
        dialogRef = updateDialogRef;
        setPaymentModalOpen(!isPaymentModalOpen);
        break;
      default:
        return;
    }
    if (!dialogRef.current) {
      return;
    }
    dialogRef.current.hasAttribute("open")
      ? dialogRef.current.classList.add("closing")
      : dialogRef.current.classList.remove("closing");
    dialogRef.current.hasAttribute("open")
      ? document.body.classList.remove("hideOverflow")
      : document.body.classList.add("hideOverflow");
    dialogRef.current.hasAttribute("open")
      ? dialogRef.current.close()
      : dialogRef.current.showModal();
    if (dialogRef.current.hasAttribute("open")) {
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const updateVariable = (index, newValue) => {
      setmealsCountState((prevState) =>
        prevState.map((variable, i) => (i === index ? newValue : variable))
      );
    };
    //console.log(mealsCountState);
    //console.log(selectedIndex, weekCount);
    updateVariable(selectedIndex, weekCount);
  }, [index, weekCount]);

  const checkCounts = () => {
    return mealsCountState.every((count) => count >= 3 || count === 0);
  };
  const handleClick = async (e) => {
    e.preventDefault();

    for (const [weekstarting, meals] of Object.entries(currentSelections)) {
      const allCounts = checkCounts();
      if (!allCounts) {
        handleOpen();
        return;
      }
    }

    const transformedSelectionsTemp = transformSelectionsToMealsByStartDate(
      currentSelections,
      totalorder
    );
    setTransformedSelections(transformedSelectionsTemp);

    if (totalorder - originalCredit < 1) {
      toggleDialog("paymentModal");

      const updateUser = async (userId, field, value) => {
        try {
          const response = await axios.patch("/api/auth/updateUser", {
            userId,
            field,
            value,
          });
        } catch (error) {
          console.error("Error updating user:", error);
        }
      };

      if (credit > 0) {
        updateUser(userId, "credit", originalCredit + credit);
      } else if (originalCredit > 0) {
        updateUser(userId, "credit", originalCredit - totalorder);
      }

      try {
        const mealsByStartDate = Object.keys(currentSelections).map(
          (weekstarting) => {
            // Calculate the delivery date for each weekstarting
            let date = new Date(
              calculateUpcomingDay(targetDay, weekstarting).upcomingDate
            );
            console.log("date", date);
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, "0");
            let day = date.getDate().toString().padStart(2, "0");
            const deliveryDate = `${year}-${month}-${day}`;
            console.log("deliveryDate", deliveryDate);
            return {
              weekstarting: weekstarting,
              deliveryDay: authState.user.deliveryDay,
              deliveryDate: deliveryDate,
              deliverTimeSlot: authState.user.deliverTimeSlot,
              servings: people,
              mealSelections: currentSelections[weekstarting],
              ordertotal: calculateOrderTotal(currentSelections[weekstarting]),
            };
          }
        );

        const response = await axios
          .put(`/api/auth/updateUserMealSelections/${userId}`, {
            mealsByStartDate,
          })
          .then((response) => {
            setUpdateMessage(response.data.message);
            setLoading(false);
          });
      } catch (error) {
        console.error(`Error updating meal selections:`, error);
        setLoading(false); // Ensure loading state is turned off on error as well
      }
    } else {
      toggleDialog("checkoutModal");
      setLoading(true);
      e.preventDefault();
    }
  };

  useEffect(() => {
    const getTotalCount = (array) => {
      if (!Array.isArray(array)) {
        return 0;
      }
      const total = array.reduce((sum, item) => sum + item.count, 0);
      //console.log("Total count:", total);
      return total;
    };

    const mostRecentMondaySelections = currentSelections[datelast] || [];
    if (mostRecentMondaySelections.length === 0) {
      setWeekCount(0);
    } else {
      setWeekCount(getTotalCount(currentSelections[datelast]));
    }
  }, [currentSelections, datelast, selectedIndex]);

  const getNextDay = (weekStarting) => {
    const dayOfWeekMapping = { sunday: 0, wednesday: 3, friday: 5 };
    const targetDayOfWeek = dayOfWeekMapping[deliveryDay.toLowerCase()];

    const startOfWeek = new Date(weekStarting);
    const currentDay = startOfWeek.getDay();

    // Calculate days until the next target day
    let daysUntilNextDay = targetDayOfWeek - currentDay;
    if (daysUntilNextDay <= 0) {
      daysUntilNextDay += 7; // Ensure it moves to the next week if today is the target day
    }

    const nextDay = new Date(startOfWeek);
    nextDay.setDate(startOfWeek.getDate() + daysUntilNextDay);

    //Adjust for the year transition from December to January
    if (nextDay.getMonth() === 0 && startOfWeek.getMonth() === 11) {
      nextDay.setFullYear(startOfWeek.getFullYear() + 1);
    }

    return nextDay;
  };

  useEffect(() => {
    if (datelast) {
      const dateSelections = currentSelections[datelast] || [];

      const totalCountm = dateSelections.reduce(
        (sum, selection) => sum + selection.count,
        0
      );
    }
  }, [datelast, currentSelections]);

  const handleSelectionAdd = (event, mealId, mostRecentMonday) => {
    event.stopPropagation();
    const totalCountM = (currentSelections[mostRecentMonday] || []).reduce(
      (sum, item) => sum + item.count,
      0
    );
    if (totalCountM < nmeals) {
      let imgSrc =
        event.currentTarget.parentNode.parentNode.parentNode.querySelector(
          ".content img"
        ).src;

      const parts = imgSrc.split("/");
      const fileNameA = parts[parts.length - 1];
      const fileName = decodeURIComponent(fileNameA); // Decode the filename
      console.log("fileName", fileName);
      console.log("smallimgs3", smallimgs3);
      switch (selectedIndex) {
        case 0:
          setSmallimgs1([...smallimgs1, fileName]);
          setSmallimgs(smallimgs1);
          break;
        case 1:
          setSmallimgs2([...smallimgs2, fileName]);
          setSmallimgs(smallimgs2);
          break;
        case 2:
          setSmallimgs3([...smallimgs3, fileName]);
          setSmallimgs(smallimgs3);
          break;
        case 3:
          setSmallimgs4([...smallimgs4, fileName]);
          setSmallimgs(smallimgs4);
          break;
        default:
          setSmallimgs([]);
          break;
      }

      setCurrentSelections((prevSelections) => {
        const selectionsForDate = prevSelections[mostRecentMonday] || [];
        const existingSelection = selectionsForDate.find(
          (selection) => selection.meal === mealId
        );

        let updatedSelections;
        let incrementalChange = 0;

        if (existingSelection) {
          updatedSelections = selectionsForDate.map((selection) => {
            if (selection.meal === mealId) {
              // Calculate incremental change for existing meal being incremented
              const meal = MealsList.find((meal) => meal._id === mealId);
              const isGourmet = meal && meal.mealtype.includes("gourmet");
              incrementalChange = pricepermeal + (isGourmet ? gourmetPrice : 0); // Price for one meal
              incrementalChange *= people; // Factor in the number of people

              return { ...selection, count: selection.count + 1 };
            } else {
              return selection;
            }
          });
        } else {
          // Calculate incremental change for new meal being added
          const meal = MealsList.find((meal) => meal._id === mealId);
          const isGourmet = meal && meal.mealtype.includes("gourmet");
          incrementalChange = pricepermeal + (isGourmet ? gourmetPrice : 0); // Price for one meal
          incrementalChange *= people; // Factor in the number of people

          updatedSelections = [
            ...selectionsForDate,
            { meal: mealId, count: 1 },
          ];
        }

        setTotalorder((prev) => {
          const previousTotal = typeof prev === "number" ? prev : 0;
          console.log("incrementalChange", previousTotal + incrementalChange);
          setCredit((prevCredit) => prevCredit - incrementalChange);

          return previousTotal + incrementalChange;
        });

        setOrderCost((prevCosts) => ({
          ...prevCosts,
          [mostRecentMonday]: calculateOrderTotal(updatedSelections),
        }));
        console.log(
          "prevSelections",
          prevSelections,
          "updatedSelections",
          updatedSelections,
          "mostRecentMonday",
          mostRecentMonday
        );
        return { ...prevSelections, [mostRecentMonday]: updatedSelections };
      });

      if (mealsCount < nmeals) {
        setMealsCount((prevState) => prevState + 1);
        const counter = event.currentTarget
          .closest(".mealCard")
          .querySelector(".counter");
        const button = event.currentTarget
          .closest(".mealCard")
          .querySelector("button");
        counter.innerText = parseInt(counter.innerText) + 1;
        button.classList.remove("show");
        button.classList.add("hide");
        event.currentTarget.closest(".mealCard").classList.add("addactive");
      }
    }
  };
  useEffect(() => {
    //console.log("previousTotal updated:", totalorder, "newcredot", credit);
  }, [totalorder]);
  const calculateOrderTotal = (selections) => {
    let total = 0;
    selections.forEach((selection) => {
      const meal = MealsList.find((meal) => meal._id === selection.meal);
      const isGourmet = meal && meal.mealtype.includes("gourmet");
      total +=
        selection.count * (pricepermeal + (isGourmet ? gourmetPrice : 0));
    });
    return total * people;
  };

  // Use an effect to update the weekly order when currentSelections or selectedIndex changes
  // useEffect(() => {
  //   const updateWeeklyOrder = (index) => {
  //     const totalOrderForWeek = currentSelections[index]
  //       ? calculateOrderTotal(currentSelections[index])
  //       : 0;

  //     setWeeklyOrders((prevWeeklyOrders) => {
  //       const newWeeklyOrders = [...prevWeeklyOrders];
  //       newWeeklyOrders[index] = totalOrderForWeek;
  //       return newWeeklyOrders;
  //     });
  //   };

  //   // Call the updateWeeklyOrder function with the selected index
  //   if (selectedIndex !== null && selectedIndex !== undefined) {
  //     updateWeeklyOrder(selectedIndex);
  //   }
  // }, [currentSelections, selectedIndex]);
  useEffect(() => {
    // console.log("totalorder", totalorder);
    //console.log("current selection", currentSelections);
  }, [totalorder]);
  // // Use another effect to calculate the total order whenever weeklyOrders changes
  // useEffect(() => {
  //   const newTotalOrder = weeklyOrders.reduce((sum, order) => sum + order, 0);
  //   setTotalOrder(newTotalOrder);
  // }, [weeklyOrders]);

  // // Effect to update balance and credit whenever totalOrder changes
  // useEffect(() => {
  //   console.log(
  //     "weeklyOrders",
  //     weeklyOrders,
  //     "newTotalOrder",
  //     totalOrder,
  //     "DbOrderTotal",
  //     DbOrderTotal,
  //     "DbOrderTotals",
  //     "originalCredit",
  //     originalCredit
  //   );

  //   if (totalOrder > DbOrderTotal) {
  //     setNewBalance(totalOrder - DbOrderTotal - originalCredit);
  //     setCredit(
  //       originalCredit - totalOrder >= 0 ? originalCredit - totalOrder : 0
  //     );
  //   } else {
  //     setNewBalance(
  //       totalOrder - DbOrderTotal >= 0 ? totalOrder - DbOrderTotal : 0
  //     );
  //     setCredit(DbOrderTotal - totalOrder + originalCredit);
  //   }
  // }, [totalOrder, DbOrderTotals]);

  // useEffect(() => {
  //   console.log("totalorder", totalorder);
  //   if (totalorder > DbOrderTotal) {
  //     setNewBalance(totalorder - DbOrderTotal - originalCredit);
  //     setCredit(
  //       originalCredit - totalorder >= 0 ? originalCredit - totalorder : 0
  //     );
  //   } else {
  //     setNewBalance(
  //       totalorder - DbOrderTotal >= 0 ? totalorder - DbOrderTotal : 0
  //     );
  //     setCredit(DbOrderTotal - totalorder + originalCredit);
  //   }
  // }, [totalorder]);

  // useEffect(() => {
  //   console.log("newBalance", newBalance);
  //   console.log("credit", credit);
  //   console.log("DbOrderTotal", DbOrderTotal);
  // }, [newBalance, credit, DbOrderTotal]);

  const handleSelectionRemove = (event, mealId, mostRecentMonday) => {
    // console.log("mostRecentMonday (remove):", mostRecentMonday);
    event.stopPropagation();
    const totalCountM = (currentSelections[mostRecentMonday] || []).reduce(
      (sum, item) => sum + item.count,
      0
    );
    // console.log("currentSel", currentSelections);
    if (totalCountM > 0) {
      let imgSrc =
        event.currentTarget.parentNode.parentNode.parentNode.querySelector(
          ".content img"
        ).src;
      const parts = imgSrc.split("/");
      const fileNameA = parts[parts.length - 1];
      const fileName = decodeURIComponent(fileNameA);
      console.log("fileNameE", fileName);
      switch (selectedIndex) {
        case 0:
          setSmallimgs1((prevSmallimgs1) => {
            const index = prevSmallimgs1.findIndex((item) => item === fileName);
            if (index !== -1) {
              // Create a new array without the first matching element
              return [
                ...prevSmallimgs1.slice(0, index),
                ...prevSmallimgs1.slice(index + 1),
              ];
            }
            return prevSmallimgs1; // No match found, return the original array
          });
          setSmallimgs((prevSmallimgs) => {
            const index = prevSmallimgs.findIndex((item) => item === fileName);
            if (index !== -1) {
              return [
                ...prevSmallimgs.slice(0, index),
                ...prevSmallimgs.slice(index + 1),
              ];
            }
            return prevSmallimgs;
          });
          break;
        case 1:
          setSmallimgs2((prevSmallimgs2) => {
            const index = prevSmallimgs2.findIndex((item) => item === fileName);
            if (index !== -1) {
              // Create a new array without the first matching element
              return [
                ...prevSmallimgs2.slice(0, index),
                ...prevSmallimgs2.slice(index + 1),
              ];
            }
            return prevSmallimgs2; // No match found, return the original array
          });
          setSmallimgs((prevSmallimgs) => {
            const index = prevSmallimgs.findIndex((item) => item === fileName);
            if (index !== -1) {
              return [
                ...prevSmallimgs.slice(0, index),
                ...prevSmallimgs.slice(index + 1),
              ];
            }
            return prevSmallimgs;
          });
          break;
        case 2:
          setSmallimgs3((prevSmallimgs3) => {
            const index = prevSmallimgs3.findIndex((item) => item === fileName);
            if (index !== -1) {
              // Create a new array without the first matching element
              return [
                ...prevSmallimgs3.slice(0, index),
                ...prevSmallimgs3.slice(index + 1),
              ];
            }
            return prevSmallimgs3; // No match found, return the original array
          });
          setSmallimgs((prevSmallimgs) => {
            const index = prevSmallimgs.findIndex((item) => item === fileName);
            if (index !== -1) {
              return [
                ...prevSmallimgs.slice(0, index),
                ...prevSmallimgs.slice(index + 1),
              ];
            }
            return prevSmallimgs;
          });
          break;
        case 3:
          setSmallimgs4((prevSmallimgs4) => {
            const index = prevSmallimgs4.findIndex((item) => item === fileName);
            if (index !== -1) {
              // Create a new array without the first matching element
              return [
                ...prevSmallimgs4.slice(0, index),
                ...prevSmallimgs4.slice(index + 1),
              ];
            }
            return prevSmallimgs4; // No match found, return the original array
          });
          setSmallimgs((prevSmallimgs) => {
            const index = prevSmallimgs.findIndex((item) => item === fileName);
            if (index !== -1) {
              return [
                ...prevSmallimgs.slice(0, index),
                ...prevSmallimgs.slice(index + 1),
              ];
            }
            return prevSmallimgs;
          });
          break;
        default:
          setSmallimgs([]);
          break;
      }
    }
    setCurrentSelections((prevSelections) => {
      const selectionsForDate = prevSelections[mostRecentMonday] || [];
      //   console.log("selectionsForDate", selectionsForDate);
      const existingSelection = selectionsForDate.find(
        (selection) => selection.meal === mealId
      );

      if (!existingSelection) {
        // If the meal isn't selected, there's nothing to remove
        return prevSelections;
      }

      let updatedSelections;
      let incrementalChange = 0;

      if (existingSelection.count > 1) {
        // Decrement count if it's greater than 1
        updatedSelections = selectionsForDate.map((selection) => {
          if (selection.meal === mealId) {
            // Calculate incremental change (negative)
            const meal = MealsList.find((meal) => meal._id === mealId);
            const isGourmet = meal && meal.mealtype.includes("gourmet");
            incrementalChange = -(
              pricepermeal + (isGourmet ? gourmetPrice : 0)
            ); // Negative price
            incrementalChange *= people;

            return { ...selection, count: selection.count - 1 };
          } else {
            return selection;
          }
        });
      } else {
        // Remove the selection entirely if count is 1
        // Calculate incremental change (negative)
        const meal = MealsList.find((meal) => meal._id === mealId);
        const isGourmet = meal && meal.mealtype.includes("gourmet");
        incrementalChange = -(pricepermeal + (isGourmet ? gourmetPrice : 0)); // Negative price
        incrementalChange *= people;

        updatedSelections = selectionsForDate.filter(
          (selection) => selection.meal !== mealId
        );
      }
      setTotalorder((prev) => {
        const previousTotal = typeof prev === "number" ? prev : 0;
        const newTotal = previousTotal + incrementalChange;

        if (newTotal < 0) {
          // If the new total is negative, create a credit
          setCredit(-newTotal); // Store the positive value in credit
          //console.log("newcredot", credit);
          return newTotal; // Set totalorder to remainder if any
        } else {
          // Otherwise, update totalorder normally
          // setCredit(0);
          return newTotal;
        }
      });

      setOrderCost((prevCosts) => ({
        ...prevCosts,
        [mostRecentMonday]: calculateOrderTotal(updatedSelections), // Recalculate for correctness
      }));

      return { ...prevSelections, [mostRecentMonday]: updatedSelections };
    });

    //Potentially remove.  Check after testing to see if it is needed.
    if (mealsCount > 0) {
      setMealsCount((prevState) => prevState - 1);
    }
    if (mealsCount === 0) {
      const counter = event.currentTarget
        .closest(".mealCard")
        .querySelector(".counter");
      const button = event.currentTarget
        .closest(".mealCard")
        .querySelector("button");
      counter.innerText = parseInt(counter.innerText) - 1;
      // button.classList.add("show");
      // button.classList.remove("hide");
      event.currentTarget.closest(".mealCard").classList.remove("addactive");
    }
  };

  useEffect(() => {
    console.log("credit", credit);
  }, [credit]);

  const handleFilter = (selectedCategory) => {
    if (selectedCategory === "clear filter") {
      setSelectedFilters([]);
    } else {
      if (selectedFilters.includes(selectedCategory)) {
        let filters = selectedFilters.filter((el) => el !== selectedCategory);
        setSelectedFilters(filters);
      } else {
        setSelectedFilters([...selectedFilters, selectedCategory]);
      }
    }
  };

  useEffect(() => {
    filterItems();
  }, [selectedFilters, filteredMealsList]);

  const filterItems = () => {
    if (selectedFilters.length > 0) {
      let tempItems = selectedFilters.map((selectedCategory) => {
        let temp = filteredMealsList.filter((item) =>
          item.mealtype.some((type) => type.includes(selectedCategory))
        );
        // console.log(temp);
        return temp;
      });

      const finaltempItems = tempItems.flat().reduce((acc, current) => {
        const index = acc.findIndex((item) => item._id.includes(current._id));

        if (index === -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      setMealsList(finaltempItems.flat());
    } else {
      setMealsList(filteredMealsList);
    }
  };

  const containsGourmet = (mealtype) => {
    if (Array.isArray(mealtype) && mealtype.length > 0) {
      // Split the first element of the array by commas and trim whitespace
      const typesArray = mealtype[0].split(",").map((item) => item.trim());
      // Check if 'gourmet' is in the resulting array
      return typesArray.includes("gourmet");
    }
    return false;
  };

  useEffect(() => {
    // console.log("nmeals", nmeals);
    const handleMutations = (mutationsList, observer) => {
      const buttons = document.querySelectorAll(".addMeal");
      buttonsRef.current = Array.from(buttons);

      if (buttons && buttons.length > 0) {
        // console.log("melass count", totalCount, nmeals, index);

        if (weekCount === nmeals) {
          buttons.forEach((button) => {
            if (button) {
              button.classList.add("disabled");
            }
          });
        } else {
          buttons.forEach((button) => {
            if (button) {
              button.classList.remove("disabled");
            }
          });
        }
      }
    };

    // Create an instance of `MutationObserver`
    const observer = new MutationObserver(handleMutations);

    // Start observing the DOM for changes in the target node
    const config = { childList: true, subtree: true };
    observer.observe(document.body, config);

    handleMutations();

    // Clean up the observer when the component unmounts or when the dependencies change
    return () => {
      observer.disconnect();
    };
  }, [datelast, weekCount, nmeals, selectedDate]);

  // Fetch detailed meal data
  useEffect(() => {
    const fetchMealDetails = async () => {
      const mealIds = Object.values(currentSelections)
        .flat()
        .map((selection) => selection.meal);
      try {
        const response = await axios.post("/api/auth/getMealDetails", {
          mealIds,
        });
        const mealsData = response.data;
        // Merge meal details with current selections
        const mergedData = {};
        Object.entries(currentSelections).forEach(
          ([weekstarting, selections]) => {
            mergedData[weekstarting] = selections.map((selection) => {
              const mealDetail = mealsData.find(
                (meal) => meal._id === selection.meal
              );
              return { ...selection, ...mealDetail };
            });
          }
        );
        setMergedMealsData(mergedData);
        // console.log(mergedData, currentDeliveryDate.toLocaleDateString());
      } catch (error) {
        console.error("Error fetching meal details:", error);
      }
    };
    fetchMealDetails();
  }, [currentSelections]);

  // let currentDeliveryDate = getNextDay(authState.user.deliveryDay); // Get the first delivery date

  const handlePayment = async (event) => {
    event.stopPropagation();

    console.log("nmeals", nmeals);
    //updateUser(userId, "noMeals", nmeals);
    if (!checked) {
      if (!add1 || !add2 || !city || !street) {
        if (!add1) {
          document.querySelector(".add1").style.display = "block";
          setAdd1Message("Please enter address");
        }

        if (!add2) {
          document.querySelector(".add2").style.display = "block";
          setAdd2Message("Please enter address");
        }
        if (!city) {
          document.querySelector(".city").style.display = "block";
          setCityMessage("Please enter city");
        }

        if (!street) {
          document.querySelector(".street").style.display = "block";
          setStreetMessage("Please enter street");
        }
        return;
      }
    }

    let payload;
    if (!checked) {
      payload = {
        amount: totalorder * 100,
        items: [
          {
            name: "Meals",
            amount: totalorder * 100,
            description: `Meals`,
            quantity: 1,
          },
        ],
        billing_data: {
          addressLine1: add1,
          addressLine2: add2,
          first_name: authState.user.firstName,
          last_name: authState.user.lastName,
          street: street,
          town: city,
          phone_number: authState.user.contact,
          country: "UAE",
          email: authState.user.email,
        },
        customer: {
          first_name: authState.user.firstName,
          last_name: authState.user.lastName,
          email: authState.user.email,
        },
      };
    } else {
      payload = {
        amount: (totalorder - originalCredit) * 100,
        items: [
          {
            name: "Meals",
            amount: (totalorder - originalCredit) * 100,
            description: `Meals`,
            quantity: 1,
          },
        ],
        billing_data: {
          addressLine1: authState.user.addressLine1,
          addressLine2: authState.user.addressLine2,
          first_name: authState.user.firstName,
          last_name: authState.user.lastName,
          street: authState.user.street,
          town: authState.user.town,
          phone_number: authState.user.contact,
          country: "UAE",
          email: authState.user.email,
        },
        customer: {
          first_name: authState.user.firstName,
          last_name: authState.user.lastName,
          email: authState.user.email,
        },
      };
    }

    try {
      const payResponse = await axios.post("/api/auth/payment", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const orderId = payResponse.data.payment_keys[0].order_id;
      //console.log("Extracted order_id:", orderId);
      const transformedSelectionsTemp = transformSelectionsToMealsByStartDate(
        currentSelections,
        totalorder - originalCredit
      );
      setTransformedSelections(transformedSelectionsTemp);

      setNewOrderObject(
        createOrderObject(
          orderId,
          authState.user._id,
          transformedSelectionsTemp,
          targetDay
        )
      );

      setClientSecret(payResponse.data.client_secret);
    } catch (error) {
      console.error(error);
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
      }
    }
  };
  useEffect(() => {
    if (newOrderObject.paymentId && clientSecret) {
      const sendDataToDatabase = async () => {
        try {
          const response = await axios.post(
            "/api/auth/createTempOrder",
            newOrderObject,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          // Redirect to payment page
          window.location.href = `https://uae.paymob.com/unifiedcheckout/?publicKey=are_pk_test_IUUNrDEOL7fiDIE9mHncauf607Epi5Q6&clientSecret=${clientSecret}`;
        } catch (error) {
          console.error("Error sending data to database:", error);
        }
      };

      sendDataToDatabase();
    }
  }, [newOrderObject, clientSecret]);

  const createOrderObject = (
    paymentId,
    userId,
    transformedSelections,
    targetDay
  ) => {
    if (!Array.isArray(transformedSelections)) {
      throw new Error("transformedSelections must be an array");
    }

    const mealsByStartDate = transformedSelections.map((selection) => {
      const { upcomingDate } = calculateUpcomingDay(
        targetDay,
        selection.weekstarting
      );
      const year = upcomingDate.getFullYear();
      const month = (upcomingDate.getMonth() + 1).toString().padStart(2, "0");
      const day = upcomingDate.getDate().toString().padStart(2, "0");
      const deliveryDate = `${year}-${month}-${day}`;

      // Calculate totalorder for each selection
      const totalorderWeekly = calculateOrderTotal(selection.mealSelections);
      //setTotalorder((prev) => prev + totalorderWeekly);
      // console.log("totalorder", totalorder);

      return {
        weekstarting: selection.weekstarting,
        deliveryDay: selection.deliveryDay,
        deliveryDate: deliveryDate,
        deliverTimeSlot: selection.deliverTimeSlot,
        servings: selection.servings,
        mealSelections: selection.mealSelections,
        driverName: selection.driverName,
        ordertotal: totalorderWeekly,
        totalorder: totalorder - originalCredit,
      };
    });

    return {
      paymentId,
      userId,
      total: mealsByStartDate.reduce(
        (sum, selection) => sum + selection.ordertotal,
        0
      ),
      mealsByStartDate: mealsByStartDate,
      driver: "", // Assuming driver field is empty; update based on your logic
    };
  };

  const transformSelectionsToMealsByStartDate = (
    currentSelections,
    orderTotal
  ) => {
    const UserdeliveryDay = authState.user.deliveryDay;

    return Object.entries(currentSelections)
      .map(([weekstarting, selections]) => {
        const mealSelections = selections.map((selection) => ({
          meal: selection.meal,
          count: selection.count,
        }));

        return {
          weekstarting,
          deliveryDay: UserdeliveryDay,
          deliveryDate: getNextDay(
            weekstarting,
            UserdeliveryDay,
            firstDD
          ).toLocaleDateString(),
          deliverTimeSlot: authState.user.deliverTimeSlot,
          servings: authState.user.noMeals,
          mealSelections,
          ordertotal: orderTotal[weekstarting] || 0,
          driverName: authState.user.driverName || "",
        };
      })
      .filter((entry) => entry.mealSelections.length > 0); // Filter out entries with empty mealSelections
  };

  const handleCheck = (e) => {
    setChecked((prevChecked) => {
      const newChecked = !prevChecked;
      // console.log("checked", newChecked);

      if (!newChecked) {
        document.querySelector(".billingAddress").style.display = "flex";
      } else {
        document.querySelector(".billingAddress").style.display = "none";
      }

      return newChecked;
    });
  };

  const addMeals = (event) => {
    event.stopPropagation();
    setNmeals((prevState) => prevState + 1);
  };

  const emptySlots = nmeals - smallimgs.length;
  //console.log("currentSelections", currentSelections[lastMonday]);
  return (
    <>
      <div className="dashboardContainer">
        <div className="userContainer">
          {/* <div className="userLinks">
                <UserPanel />
                </div>
        */}
          <div className="userData container ">
            <div className="userDashboardAdmin">
              <div className="headerSection">
                <h1>Select Meals</h1>
                <h2>Change or select your Meals</h2>
                <Link to={"/dashboard"}>
                  <button className="btd">Back to dashboard</button>
                </Link>
              </div>

              <div className="mealsList">
                <div className="mealImages">
                  <ul className="chosenmeals">
                    {smallimgs.map((img, index) => (
                      <li className="chosenmeal" key={index} data-index={index}>
                        <img src={`/images/meals/${img}`} alt={img} />
                      </li>
                    ))}
                    {Array.from({ length: emptySlots }, (_, index) => (
                      <li
                        className="chosenmeal"
                        key={smallimgs.length + index}
                      ></li>
                    ))}
                  </ul>
                  <div className="selectedTextDiv">
                    <p className="selectedText">
                      You have selected {weekCount} meals
                    </p>

                    {nmeals < 5 ? (
                      <p className="addmeals" onClick={addMeals}>
                        Add meal
                      </p>
                    ) : (
                      ""
                    )}
                    {/* {nmeals > 3 ? <p onClick={reduceMeals}>Remove meal</p> : ""} */}
                    {/* <p>{noMeals}</p> */}
                  </div>
                  <button className="saveMeals" onClick={handleClick}>
                    Save Meals
                  </button>
                </div>
                <div className="datesContainer">
                  <WeeklyDateRanges
                    onRequest={fetchMeals}
                    onLastMonday={handleLastMonday}
                    onSelectedIndexChange={handleSelectionChange}
                    handleIsoDate={isodate}
                  />
                </div>
                <div className="filterContainer">
                  <ul className="filters">
                    {filters.map((category, idx) => (
                      <li
                        className={`filterbutton ${category.replace(
                          / /g,
                          ""
                        )} ${
                          selectedFilters?.includes(category) ? "active" : ""
                        } `}
                        key={`filters-${idx}`}
                        onClick={() => handleFilter(category)}
                      >
                        {category}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="mealList">
                  {loading ? (
                    <Loading />
                  ) : errorMessage ? (
                    <div className="error">
                      <p>{errorMessage}</p>
                    </div>
                  ) : (
                    MealsList.map((meal, index) => {
                      const baseName = meal.image.split(".")[0]; // Extract base name from image file name
                      const ext = meal.image.split(".")[1]; // Extract extension from image file name

                      return (
                        <div
                          key={meal._id}
                          data-date={meal.weekstarting}
                          id={meal._id}
                          className={`mealCard ${
                            (currentSelections[datelast] || []).some(
                              (selection) => selection.meal === meal._id
                            )
                              ? "addactive"
                              : ""
                          } ${
                            containsGourmet(meal.mealtype) ? "gourmet" : ""
                          } ${activeIndex === index ? "expanded" : ""}`}
                          onClick={() => {
                            setSelectedMeal(meal);
                          }}
                        >
                          <div className="imgContainer">
                            <img
                              src={`/images/meals/${baseName}-x.${ext}`} // Default to large image
                              alt={meal.title}
                            />
                          </div>
                          <div className="contentContainer">
                            <div className="mealDetails">
                              <h4>{meal.title}</h4>
                              <p>{meal.subtitle}</p>
                            </div>
                            <div className="mealtypes">
                              <ul>
                                {meal.mealtype
                                  .toString()
                                  .split(",")
                                  .map((type, index) => (
                                    <li
                                      key={index}
                                      className={type.replace(/ /g, "")}
                                    >
                                      {type}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                            <div
                              className="addRemove"
                              style={{
                                pointerEvents: disableClicks ? "none" : "auto",
                                opacity: disableClicks ? 0.5 : 1,
                              }}
                            >
                              <button
                                ref={(el) => (buttonsRef.current[index] = el)}
                                onClick={(e) =>
                                  handleSelectionAdd(e, meal._id, datelast)
                                }
                                className={`addMeal ${
                                  (currentSelections[datelast] || []).find(
                                    (selection) =>
                                      selection.meal === meal._id &&
                                      selection.count > 0
                                  )
                                    ? "hide"
                                    : ""
                                }`}
                              >
                                Add Recipe
                              </button>
                              <p
                                onClick={(e) =>
                                  handleSelectionRemove(e, meal._id, datelast)
                                }
                              >
                                -
                              </p>
                              <p className="counter">
                                {(currentSelections[datelast] || []).find(
                                  (selection) => selection.meal === meal._id
                                )?.count || 0}
                              </p>
                              <p
                                onClick={(e) =>
                                  handleSelectionAdd(e, meal._id, datelast)
                                }
                              >
                                +
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedMeal && (
        <MealModal meal={selectedMeal} onClose={() => setSelectedMeal(null)} />
      )}
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <div className="modalTop">
          <span>Please check your selections</span>
          <button className="close">X</button>
        </div>

        <div className="modalBody">
          <div className="modalWarning">
            <p>{`Please select ${nmeals} meals for each date you would like meals delivered on`}</p>
          </div>
        </div>
      </Modal>

      <dialog className="filter" ref={checkoutDialogRef} id="checkoutModal">
        <div className="modalTop">
          <span>Checkout</span>
          <button
            className="close"
            onClick={() => {
              toggleDialog("checkoutModal");
              setLoading(false);
            }}
          >
            X
          </button>
        </div>
        <div className="modalBody">
          <div>
            <p>Checkout summary</p>
          </div>
          <div className="mealList">
            {Object.entries(mergedMealsData).map(
              ([weekstarting, meals]) =>
                meals.length > 0 && (
                  <div
                    className="summaryMeals checkoutMeals"
                    key={weekstarting}
                  >
                    <h3>Week Starting: {weekstarting}</h3>

                    {meals.map((meal, index) => {
                      const baseName = meal.image.split(".")[0]; // Extract base name from image file name
                      const ext = meal.image.split(".")[1]; // Extract extension from image file name

                      return (
                        <div className="mealCard" key={index}>
                          <div className="imgContainer">
                            <img
                              src={`/images/meals/${baseName}-x.${ext}`}
                              alt={meal.title}
                              width="50"
                              height="50"
                            />
                          </div>
                          <div className="contentContainer">
                            <div className="mealDetails">
                              <h4>{meal.title}</h4>
                              <p>{meal.subtitle}</p>
                              <p>{meal.desc}</p>
                            </div>
                            <div className="mealsBottom mealsCount">
                              <p>{meal.count} meals selected</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )
            )}
          </div>
          <div className="summaryDiv ">
            <h2>Price Details</h2>
            <div className="boxesData">
              <div className="boxesPrice">
                <p>Price per serving: {pricepermeal}AED</p>
                <hr></hr>

                <p>
                  {people * nmeals} Servings
                  <span>{people * nmeals * pricepermeal}AED</span>
                </p>
                {/* {totalGourmetCount > 0 && (
                  <p>
                    Extra cost of {totalGourmetCount} gourmet meals:
                    <span>{totalGourmetCost}AED</span>
                  </p>
                )} */}
                <p>
                  Delivery <span>Free</span>
                </p>
                <p>
                  Credit
                  {originalCredit > 0 && <span>{originalCredit}AED</span>}
                </p>
                <hr></hr>
                <p className="totalPrice">
                  Amount to pay <span>{totalorder - originalCredit}AED</span>
                </p>
              </div>
            </div>
          </div>
          <div className="containerForm payment">
            <div className="right">
              <form>
                <div className="paymentForm">
                  <h3>Billing address</h3>
                  <div className="checkboxAddress">
                    <input
                      type="checkbox"
                      id="sameAddress"
                      name="sameAddress"
                      onChange={handleCheck}
                      checked={checked}
                    />
                    <label htmlFor="sameAddress">
                      Same as delivery address
                    </label>
                  </div>
                  <div className="billingAddress">
                    <div className="errorContainer">
                      <input
                        type="text"
                        id="add1"
                        name="add1"
                        placeholder="Enter Address Line 1"
                        value={add1}
                        onChange={handleChange}
                      />
                      <div className="error fnerror inputerror add1">
                        {add1Message}
                      </div>
                    </div>
                    <div className="errorContainer">
                      <input
                        type="text"
                        id="add2"
                        name="add2"
                        placeholder="Enter Address Line 2"
                        value={add2}
                        onChange={handleChange}
                      />
                      <div className="error fnerror inputerror add2">
                        {add2Message}
                      </div>
                    </div>
                    <div className="errorContainer">
                      <input
                        type="text"
                        id="street"
                        name="street"
                        placeholder="Enter Street Name"
                        value={street}
                        onChange={handleChange}
                      />
                      <div className="error fnerror inputerror street">
                        {streetMessage}
                      </div>
                    </div>

                    <div className="errorContainer">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        placeholder="Enter City"
                        value={city}
                        onChange={handleChange}
                      />
                      <div className="error fnerror inputerror city">
                        {cityMessage}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <button type="submit" onClick={handlePayment}>
            Pay Now
          </button>
        </div>
      </dialog>
      <dialog className="filter" ref={updateDialogRef} id="paymentModal">
        <div className="modalTop">
          <span>Processing meals</span>
          <button
            className="close"
            onClick={() => (toggleDialog, navigate("/dashboard"))}
          >
            X
          </button>
        </div>
        <div className="modalBody">
          {loading ? (
            <img
              src="../images/icons/loading.gif"
              alt="loading"
              className="loading"
            />
          ) : (
            <p>{updateMessage}</p>
          )}
        </div>
      </dialog>
    </>
  );
};

export default MyMeals;
