function calculateUpcomingDay(targetDay, weekStarting) {
  console.log("targetDay", targetDay);
  const startDate = new Date(weekStarting);
  const dayOfWeek = startDate.getDay();
  let daysToAdd;

  if (targetDay === "wednesday") {
    daysToAdd = (3 - dayOfWeek + 7) % 7;
  } else if (targetDay === "friday") {
    daysToAdd = (5 - dayOfWeek + 7) % 7;
  } else if (targetDay === "sunday") {
    daysToAdd = (0 - dayOfWeek + 7) % 7; // Sunday is 0 in JavaScript Date
  } else {
    return null;
  }

  // Calculate the upcoming date
  const upcomingDate = new Date(startDate);
  upcomingDate.setDate(upcomingDate.getDate() + daysToAdd);

  // Check if the upcoming date is more than 48 hours away from today
  const today = new Date();
  const isMoreThan48HoursAway =
    (upcomingDate - today) / (1000 * 60 * 60 * 24) <= 2;

  return {
    upcomingDate,
    isMoreThan48HoursAway,
    isToday: daysToAdd === 0,
  };
}

export default calculateUpcomingDay;
