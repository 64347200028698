import axios from "axios";
import { useState, useEffect } from "react";
import AdminPanel from "./adminPanel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const OrdersByDeliveryDate = () => {
  const [orders, setOrders] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    document.title = "Deliveries Summary";
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get("api/auth/deliverysummary");
        setOrders(response.data.aggregatedData);

        const driversResponse = await axios.get("api/auth/drivers");
        console.log("Drivers Response:", driversResponse.data);
        setDrivers(driversResponse.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const DelSlotFunc = ({ userSlot }) => {
    userSlot = parseInt(userSlot);
    switch (userSlot) {
      case 1:
        return "5 - 7am";
      case 2:
        return "7 - 9am";
      case 3:
        return "5 - 7pm";
      case 4:
        return "7 - 9pm";
      default:
        return "Unknown time";
    }
  };

  const downloadPDFForDriver = (driverName) => {
    Object.entries(orders).forEach(([deliveryDate, ordersList]) => {
      const ordersForDriver = ordersList.filter(
        (order) => order.driverName === driverName
      );
      if (ordersForDriver.length > 0) {
        const doc = new jsPDF();

        doc.text(`Delivery Date: ${deliveryDate}`, 10, 10);
        doc.text(`Driver: ${driverName}`, 10, 20);

        const tableColumns = [
          "Customer Name",
          "Meal Titles",
          "Customer Address",
          "Delivery Slot",
          "Special Instruction",
          "Contact Number",
        ];
        const tableRows = ordersForDriver.map((order) => [
          order.customerName,
          order.mealTitles,
          order.customerAddress,
          DelSlotFunc({ userSlot: order.deliverySlot }),
          order.specialInstruction || "None",
          order.contactNumber,
        ]);

        doc.autoTable({
          head: [tableColumns],
          body: tableRows,
          startY: 30,
          headStyles: {
            fillColor: [0, 0, 255], // Customize the color as needed
          },
        });

        doc.save(`${driverName}_${deliveryDate}.pdf`);
      }
    });
  };

  const downloadExcelForDriver = (driverName) => {
    Object.entries(orders).forEach(([deliveryDate, ordersList]) => {
      const ordersForDriver = ordersList.filter(
        (order) => order.driverName === driverName
      );
      if (ordersForDriver.length > 0) {
        const worksheetData = [
          [
            "Customer Name",
            "Meal Titles",
            "Customer Address",
            "Delivery Slot",
            "Special Instruction",
            "Contact Number",
          ],
          ...ordersForDriver.map((order) => [
            order.customerName,
            order.mealTitles,
            order.customerAddress,
            DelSlotFunc({ userSlot: order.deliverySlot }),
            order.specialInstruction || "None",
            order.contactNumber,
          ]),
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          `Orders_${deliveryDate}`
        );

        XLSX.writeFile(workbook, `${driverName}_${deliveryDate}.xlsx`);
      }
    });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const handleDriverChange = async (
    deliveryDate,
    orderIndex,
    event,
    orderID,
    mealEntryId
  ) => {
    console.log("Order ID:", orderID);
    const newDriverName = event.target.value;

    try {
      const response = await axios.post(`/api/auth/updateOrderDriver`, {
        orderID,
        driverName: newDriverName,
        mealEntryId: mealEntryId,
      });

      console.log(response.data);

      // Update the state only after the server call is successful
      setOrders((prevOrders) => {
        const updatedOrders = { ...prevOrders };
        updatedOrders[deliveryDate][orderIndex].driverName = newDriverName;
        return updatedOrders;
      });
    } catch (error) {
      console.error("Error updating driver:", error);
    }
  };

  const uniqueDrivers = [
    ...new Set(
      Object.values(orders)
        .flat()
        .map((order) => order.driverName)
    ),
  ].filter((driverName) => driverName);

  return (
    <div className="adminDash">
      <h1>Deliveries Summary</h1>
      <AdminPanel />

      <div className="adminMainContent">
        <div className="deliverySummary">
          <h1>Orders Table</h1>
          {Object.entries(orders).map(([deliveryDate, ordersList]) => (
            <div key={deliveryDate}>
              <h2>Delivery Date: {deliveryDate}</h2>
              <table>
                <thead>
                  <tr>
                    <th>Customer Name</th>
                    <th>Meal Titles</th>
                    <th>Customer Address</th>
                    <th>Delivery Slot</th>
                    <th>Special Instruction</th>
                    <th>Contact Number</th>
                    <th>Assign Driver</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersList.map((order, index) => (
                    <tr key={index}>
                      <td>
                        {order.customerName}
                        <br />
                        {order.mealEntryId}
                      </td>
                      <td
                        dangerouslySetInnerHTML={{
                          __html: order.mealTitles.split(", ").join("<br />"),
                        }}
                      ></td>
                      <td className="custaddress">{order.customerAddress}</td>
                      <td>
                        {DelSlotFunc({
                          userSlot: order.deliverySlot,
                        })}
                      </td>
                      <td>{order.specialInstruction || "None"}</td>
                      <td>{order.contactNumber}</td>
                      <td>
                        <select
                          value={order.driverName || ""}
                          onChange={(event) =>
                            handleDriverChange(
                              deliveryDate,
                              index,
                              event,
                              order._id,
                              order.mealEntryId
                            )
                          }
                        >
                          <option value="">Select Driver</option>
                          {drivers.map((driver) => (
                            <option key={driver._id} value={driver.drivername}>
                              {driver.drivername}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr />
            </div>
          ))}
          <div>
            <h2>Download Reports by Driver</h2>
            {uniqueDrivers.length > 0 ? (
              uniqueDrivers.map((driverName) => (
                <div key={driverName}>
                  <button onClick={() => downloadPDFForDriver(driverName)}>
                    Download PDF for {driverName}
                  </button>
                  <button onClick={() => downloadExcelForDriver(driverName)}>
                    Download Excel for {driverName}
                  </button>
                </div>
              ))
            ) : (
              <p>No drivers assigned</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersByDeliveryDate;
