import React, { useContext, useState } from "react";
import { FormContext } from "./FormContext";
import { useLocation, Navigate } from "react-router-dom";

const withGuard = (WrappedComponent, allowedPages) => {
  return (props) => {
    const { formData } = useContext(FormContext);
    const location = useLocation();

    const currentStep = allowedPages.indexOf(location.pathname);

    if (currentStep === -1 || formData.people === 0) {
      return <Navigate to="/boxes" replace />;
    }

    // Ensure we are returning a JSX element
    return <WrappedComponent {...props} />;
  };
};

export default withGuard;
