import React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import "../styles/home.css";
import { Link } from "react-router-dom";
// import { AuthContext } from "../context/AuthContext";

const Home = () => {
  const [panelActive, setPanelActive] = useState(false);
  const [mealResponse, setMealResponse] = useState([]);
  // const { authState } = useContext(AuthContext);
  function clearAllData() {
    // Clear local storage
    //localStorage.clear();

    // Clear session storage
    // sessionStorage.clear();

    // Clear cookies
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }

    console.log("All cached data and local storage have been cleared.");
  }

  // Call the function to clear all data
  //clearAllData();

  useEffect(() => {
    const loadData = async () => {
      try {
        // Fetch the hero data
        const response = await fetch("/api/auth/homedata?page=home");
        const data = await response.json();

        if (data.length > 0) {
          const { hero, meal1, meal2, meal3 } = data[0];
          console.log("hero", hero);
          // document.addEventListener("DOMContentLoaded", () => {
          // document.querySelector(
          //   ".hero"
          // ).style.backgroundImage = `url('${hero}')`;
          //});

          // Fetch meal details
          const mealResponses = await fetch(
            `/api/auth/getMealDetailsHome?meal1=${meal1}&meal2=${meal2}&meal3=${meal3}`
          );
          const mealData = await mealResponses.json();
          setMealResponse(mealData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    loadData();
  }, []);

  const ToggleClass = (e) => {
    const lilist = document.querySelectorAll(".accordion");
    const panelList = document.querySelectorAll(".panel");

    // Determine if the clicked item is already active
    const isActive = e.currentTarget.classList.contains("active");

    // Close all panels and remove active class from all items
    lilist.forEach((element) => {
      element.classList.remove("active");
    });
    panelList.forEach((element) => {
      element.style.maxHeight = null;
      element.style.padding = "0px";
    });

    // If the clicked item was not active, activate it
    if (!isActive) {
      e.currentTarget.classList.add("active");
      let panel = e.currentTarget.nextElementSibling;
      panel.style.maxHeight = panel.scrollHeight + 70 + "px";
      panel.style.padding = "18px";
    }
  };

  useEffect(() => {
    const buttonSection = document.querySelector(".moremeals .midsection");
    const articles = document.querySelectorAll("article");
    const elementsToExpand = document.querySelectorAll(".svgSection");
    const texttoAnim = document.querySelectorAll(".animText");
    const titlestoAnim = document.querySelectorAll(".headertoAnim");
    const instaImgs = document.querySelectorAll(".instaImg");
    const midadgreens = document.querySelector(".midadgreen");
    const whys = document.querySelector(".why");
    const imageList = document.querySelector(".beHealthy");
    const scrollers = document.querySelectorAll(".scroller");

    let articlesAnimObserver = new IntersectionObserver(
      (entries) => {
        let counter = 0;
        entries.forEach((entry, index) => {
          if (entry.intersectionRatio > 0) {
            // Add delay to the animation based on the index
            const delay = index * 0.25; // 1 second delay for each subsequent entry
            entry.target.style.animation = `moremeals 2s ease ${delay}s forwards`;

            counter = counter + 0.2;
            buttonSection.style.opacity = 1;
          }
        });
      },
      {
        threshold: 1,
        rootMargin: "0px 0px -100px 0px",
      }
    );

    // Function to observe newly added articles
    const observeNewArticles = (articles) => {
      articles.forEach((element) => {
        articlesAnimObserver.observe(element);
      });
    };

    // Initial observation for existing articles
    observeNewArticles(articles);

    // Use MutationObserver to detect new articles
    let mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === 1 && node.matches(".articleClass")) {
              articlesAnimObserver.observe(node);
            }
          });
        }
      });
    });

    // Start observing the parent element where articles are added
    mutationObserver.observe(document.querySelector(".overflowSection"), {
      childList: true,
      subtree: true,
    });

    let expansionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const [{ isIntersecting }] = entries;
          // if (entry.intersectionRatio > 0) {

          if (isIntersecting) {
            entry.target.style.opacity = 1;
            if (entry.target.classList.contains("arc")) {
              entry.target.innerHTML =
                ' <object type="image/svg+xml" data="/images/rightarrowP.svg"></object>';
            }
            expansionObserver.unobserve(entry.target);
          }

          // }
        });
      },
      { rootMargin: "0px 0px 0% 0px", threshold: 0.9 }
    );

    elementsToExpand.forEach((element) => {
      expansionObserver.observe(element);
    });

    let textAnimObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            entry.target.style.animation = "heroSwipe .5s ease forwards";
          }
        });
      },
      {
        threshold: 0.9,
      }
    );
    texttoAnim.forEach((element) => {
      textAnimObserver.observe(element);
    });

    let headAnimObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            entry.target.classList.add("titleAnim");
          }
        });
      },
      {
        rootMargin: "0px 0px -150px 0px",
      }
    );

    titlestoAnim.forEach((element) => {
      headAnimObserver.observe(element);
    });

    let midasObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            entry.target.style.transform = "translateX(0%)";

            let midasContent = document.querySelectorAll(".midasContent");
            for (let i = 0; i < midasContent.length; i++) {
              midasContent[i].classList.add("activate");
            }
          }
        });
      },
      {
        rootMargin: "0px 0px -100px 0px",
      }
    );

    midasObserver.observe(midadgreens);

    let whysObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            // entry.target.style.transform = "translateX(0%)";
            let whysContent = document.querySelectorAll(".whyContent");
            for (let i = 0; i < whysContent.length; i++) {
              whysContent[i].classList.add("activate");
            }
          }
        });
      },
      {
        rootMargin: "0px 0px -100px 0px",
      }
    );

    whysObserver.observe(whys);

    let pictAnimObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            entry.target.style.opacity = "1";
          }
        });
      },
      {
        rootMargin: "0px 0px -150px 0px",
      }
    );

    pictAnimObserver.observe(imageList);

    let instaImgsAnimObserver = new IntersectionObserver(
      (entries) => {
        let counter = 0;
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            entry.target.style.animation = "instaResize 2s ease forwards";
            entry.target.style.animationDelay = counter + "s";
            counter = counter + 0.2;
          }
        });
      },
      {
        rootMargin: "0px 0px -300px 0px",
      }
    );

    instaImgs.forEach((element) => {
      instaImgsAnimObserver.observe(element);
    });

    // If a user hasn't opted in for recuded motion, then we add the animation
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        // add data-animated="true" to every `.scroller` on the page
        scroller.setAttribute("data-animated", true);

        // Make an array from the elements within `.scroller-inner`
        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        // For each item in the array, clone it
        // add aria-hidden to it
        // add it into the `.scroller-inner`
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  return (
    <>
      <div className="mainContainer homeContainer">
        <section className="hero">
          <div>
            <p className="cook">Cookit.</p>
            {/* {authState.isAuthenticated ? (
              <Link to="/dashboard">
                <button className="getStartedBut">View Dashboard</button>
              </Link>
            ) : (
              <Link to="/boxes">
                <button className="getStartedBut">Get started</button>
              </Link>
            )} */}
          </div>
        </section>

        <section className="moremeals">
          <div className="overflowSection">
            {/* {mealResponse.map((meal) => {
              const baseNameF = meal.image.split(".")[0]; // Extract base name from image file name
              const ext = meal.image.split(".")[1]; // Extract extension from image file name
              const baseName = `${baseNameF}-x`; // Add '-x' to baseName
              return (
                <article className="articleClass" key={meal._id}>
                  <img
                    src={`images/meals/${baseName}.${ext}`}
                    alt={meal.title}
                  />

                  <h3>{meal.title}</h3>
                  <h4>{meal.subtitle}</h4>
                  <p>{meal.desc}</p>
                </article>
              );
            })} */}
            <article>
              <img src="/images/26.jpg" alt="" />

              <h3>Penne Alla Norma</h3>
              <p>
                Roasted chicken, tomatoes, parmesan crisps, shaved parmesan,
                shredded kale, chopped romaine, lime squeeze, caesar
              </p>
            </article>
            <article>
              <img src="/images/COOKITFEBc.jpg" alt="" />

              <h3>Creamy Minced Beef</h3>
              <p>
                Roasted chicken, avocado, tomatoes, red onions, shredded
                cabbage, tortilla chips, spring mix, chopped romaine, lime
                squeeze, lime cilantro jalapeño vinaigrette
              </p>
            </article>
            <article>
              <img src="/images/COOKITFEB.jpg" alt="" />

              <h3>Shrimp Orzo Pasta</h3>
              <p>
                Blackened chicken, pickled onions, tomatoes, raw carrots,
                cilantro, blue cheese, za’atar breadcrumbs, shredded kale,
                chopped romaine, sweetgreen hot sauce, caesar
              </p>
            </article>
          </div>
          <div className="midsection">
            {/* {authState.isAuthenticated ? (
              <Link to="/dashboard">
                <button className="getStartedBut">View Dashboard</button>
              </Link>
            ) : (
              <>
                <Link to="/themeals">
                  <button className="getStartedBut">See all our dishes</button>
                </Link>
                <p className="linkText">
                  View all the dishes available over the next month
                </p>
              </>
            )} */}
          </div>
        </section>

        <section className="how" id="hwwrks">
          <div className="howIcons">
            <h2 className="headertoAnim">How it works</h2>
            <p className="subheading headertoAnim">4 easy steps</p>

            <div className="addPeople svgSection">
              <object
                className="newSVG"
                type="image/svg+xml"
                data="/images/add.svg"
              ></object>
            </div>
            <div className="addpeopleP animText">
              <div className="span">1</div>
              <p>
                Select how many people you are cooking for and how many times a
                week you would like a delivery
              </p>
            </div>
            <div className="curveOne svgSection arc"></div>

            <div className="meals svgSection">
              <object
                className="newSVG"
                type="image/svg+xml"
                data="/images/FPealP.svg"
              ></object>
            </div>
            <div className="mealsP animText">
              <div className="span">2</div>
              <p>Select your meals from 20 recipes</p>
            </div>
            <div className="curveTwo svgSection arc"></div>
            <div className="truck svgSection">
              <object
                className="newSVG"
                type="image/svg+xml"
                data="/images/deliver.svg"
              ></object>
            </div>
            <div className="truckP animText">
              <div className="span">3</div>
              <p>Receive your order when you want it</p>
            </div>
            <div className="curveThree svgSection arc"></div>
            <div className="cookit svgSection">
              <object
                className="newSVG"
                type="image/svg+xml"
                data="/images/cook.svg"
              ></object>
            </div>
            <div className="cookitP animText">
              <div className="span">4</div>
              <p>Cook It!</p>
            </div>
          </div>
        </section>

        <section className="midadgreen">
          <div className="midasContent">
            <h2>Join our Cookit community</h2>
            <p>
              At our heart, we believe that the act of cooking isn't just about
              putting food on the table—it's a profound expression of care,
              creativity, and connection.
            </p>
            <p>
              We're more than just a meal kit service; we're a community of
              culinary enthusiasts dedicated to bringing the joy of home cooking
              back into your life. With carefully curated ingredients and
              easy-to-follow recipe cards, we empower you to create delicious,
              wholesome meals in the comfort of your own kitchen.
            </p>
            <p>
              Beyond the convenience, there's something truly transformative
              about preparing a meal from scratch—it nourishes not only your
              body but also your soul. By prioritizing quality ingredients and
              promoting the joys of cooking, we're not just building a business;
              we're fostering a movement towards healthier, happier lifestyles.
            </p>
            <p>
              Join us in celebrating the art of home cooking and let's savor
              every moment shared around the dinner table.
            </p>
          </div>
          <img src="/images/DSC04421.jpg" alt="" className="midasContent" />
        </section>
        <section className="why">
          <img src="/images/DSC04279.jpg" alt="" className="whyContent" />
          <div className="whyContent whytext ">
            <h2>Why Cookit?</h2>
            <div>
              <h3>Quick & Easy Home Cooking</h3>
              <p>
                No need to plan, shop or prepare your ingredients. All
                pre-prepared and pre-measured ingredients and easy-to-follow
                recipes.
              </p>
            </div>
            <div>
              <h3>Convenience & Flexibitlity</h3>
              <p>
                20 new dishes to choose from every week. No commitment: Skip,
                pause or cancel at anytime. Make unlimited changes to your plan.
              </p>
            </div>

            <div>
              <h3>Healthy & Nutritious</h3>
              <p>
                Healthy balanced meals for the whole family. Calorie managed
                dishes and popular dietary preferences for you to choose from.
              </p>
            </div>
            <div>
              <h3>Quality & Freshness</h3>
              <p>
                Fresh high-quality ingredients sourced from our trusted
                suppliers. Top class kitchen facility, and 100% Biodegradable
                plastic and fully recyclable materials.
              </p>
            </div>
          </div>
        </section>

        <section className="beHealthy">
          <div className="behealthysection">
            <h2 className="headertoAnim">
              Be healthy, be inspired, be sustainable
            </h2>
            <p className="subheading headertoAnim">
              Choose from over 80 recipes a month, choose to filter by different
              selections and set your favourites
            </p>
          </div>
          <div className="scroller">
            <div className="scroller__inner">
              <img src="/images/DSC04182.jpg" alt="" />
              <img src="/images/DSC04206.jpg" alt="" />
              <img src="/images/DSC04210.jpg" alt="" />
              <img src="/images/DSC04216.jpg" alt="" />
              <img src="/images/DSC04292.jpg" alt="" />
              <img src="/images/DSC04296.jpg" alt="" />
              <img src="/images/DSC04425.jpg" alt="" />
            </div>
          </div>
          {/* <div className="midsection">
            {authState.isAuthenticated ? (
              <Link to="/dashboard">
                <button className="getStartedBut">View Dashboard</button>
              </Link>
            ) : (
              <>
                <Link to="/themeals">
                  <button className="getStartedBut">See all our dishes</button>
                </Link>
                <p className="linkText">
                  View all the dishes available over the next month
                </p>
              </>
            )}
          </div> */}
        </section>
        <section className="instasection">
          <img
            className="instaicon"
            src="/images/noun-instagram-6161542.png"
            alt=""
          />
          <h2 className="headertoAnim">#cookitae</h2>
          <p className="subheading instasubheading headertoAnim">
            <Link to="https://www.instagram.com/cookit_ae/">Follow us</Link> on
            Instagram @cookitae
          </p>

          <div className="instaImages">
            <img src="/images/DSC04247.jpg" alt="" className="instaImg" />
            <img src="/images/DSC04334.jpg" alt="" className="instaImg" />
            <img src="/images/PAHS-34.jpg" alt="" className="instaImg" />
            <img src="/images/PAHS-23.jpg" className="instaImg" alt="" />
          </div>
        </section>
        <section className="faqsection">
          <div className="faqheader">
            <h2 className="headertoAnim">Questions?</h2>
          </div>
          <div className="faqs">
            <ul>
              <li className="accordion" onClick={ToggleClass}>
                <p>How do I select my meals?</p>
              </li>
              <div className="panel">
                <p>
                  You can easily select your meals by visiting our website and
                  clicking on the “The Boxes” section at the top of the page.
                  There, you'll find our weekly menu featuring a variety of
                  delicious options, including different cuisines and dietary
                  preferences. Simply browse through the meals, choose your
                  favorites, and add them to your box!
                </p>
              </div>

              <li className="accordion" onClick={ToggleClass}>
                <p>Is it possible to skip or cancel a delivery?</p>
              </li>
              <div className="panel">
                <p>
                  Absolutely! If you need to skip or cancel a delivery, you can
                  do so as long as you notify us at least 48 hours before your
                  scheduled delivery date. This way, you have the flexibility to
                  adjust your meal plan according to your needs.
                </p>
              </div>

              <li className="accordion" onClick={ToggleClass}>
                <p>
                  Is there a minimum number of portions I have to add to my box?
                </p>
              </li>
              <div className="panel">
                <p>
                  Yes, we do have a minimum requirement of 3 meals, each
                  consisting of 2 servings. This means you’ll receive 2 portions
                  per meal. If you’re cooking for one, don’t worry! You can
                  easily save the extra portions for later, so you can enjoy
                  your delicious meals at your convenience.
                </p>
              </div>
              <li className="accordion" onClick={ToggleClass}>
                <p>Can I pause my subscription? How does it work?</p>
              </li>
              <div className="panel">
                <p>
                  Yes, you can easily pause your subscription! If you find
                  yourself traveling or just need a break for any reason, simply
                  reach out to us via phone, message, or email. We’ll be more
                  than happy to assist you and pause your plan according to your
                  needs.
                </p>
              </div>

              <li className="accordion" onClick={ToggleClass}>
                <p>Do you offer vegetarian and vegan meals?</p>
              </li>
              <div className="panel">
                <p>
                  Absolutely! We offer a wide variety of vegetarian and vegan
                  meals to cater to your dietary preferences. When selecting
                  your meals, just click on the filter/preferences button to see
                  all the delicious options available for you!
                </p>
              </div>

              <li className="accordion" onClick={ToggleClass}>
                <p>Are your recipes difficult?</p>
              </li>
              <div className="panel">
                <p>
                  Not at all! Our recipes are designed to be simple and
                  enjoyable, making your cooking experience as stress-free as
                  possible. With the ingredients prepped ahead of time, all you
                  need to do is focus on cooking and enjoying your meal!
                </p>
              </div>
            </ul>
            <ul>
              <li className="accordion" onClick={ToggleClass}>
                <p>Whats included in the box?</p>
              </li>
              <div className="panel">
                <p>
                  Each of our boxes comes with all the ingredients you need,
                  meticulously chopped, cleaned, and vacuum-sealed for
                  freshness. You’ll also receive a recipe card for each meal,
                  providing you with easy-to-follow instructions and tips to
                  ensure a delicious outcome.
                </p>
              </div>

              <li className="accordion" onClick={ToggleClass}>
                <p>How quickly should I cook the recipes?</p>
              </li>
              <div className="panel">
                <p>
                  Each meal has its own cooking time, but most of our recipes
                  are designed to be prepared in under 30 minutes. This means
                  you can enjoy a fantastic meal without spending too much time
                  in the kitchen!
                </p>
              </div>

              {/* <li className="accordion" onClick={ToggleClass}>How long does it take to prepare one of your recipes ?</li>
                <div className="panel">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et
                        dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex ea
                        commodo
                        consequat.</p>
                </div> */}
              <li className="accordion" onClick={ToggleClass}>
                <p>How do I skip a delivery or change its date or address?</p>
              </li>
              <div className="panel">
                <p>
                  You can easily make these changes directly on your dashboard!
                  If you encounter any issues or need assistance, feel free to
                  reach out to us. We’re always happy to help you with any
                  adjustments you need.
                </p>
              </div>

              <li className="accordion" onClick={ToggleClass}>
                <p>How many meals do you offer per week?</p>
              </li>
              <div className="panel">
                <p>
                  You can choose from 3, 4, or 5 meals per week, selecting from
                  a diverse menu of up to 20 different meal options. This allows
                  you to customize your meal plan to fit your lifestyle and
                  taste preferences!
                </p>
              </div>

              <li className="accordion" onClick={ToggleClass}>
                <p>Do you offer meals for 1 person?</p>
              </li>
              <div className="panel">
                <p>
                  Unfortunately, our meals are designed to be served in a
                  minimum of 2 portions per meal. However, there’s no need to
                  worry! You can easily pack up any leftovers and enjoy them the
                  next day. For tips and tricks on storing and packing your
                  meals, be sure to check out our Instagram page!
                </p>
              </div>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
