import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminPanel from "./adminPanel";

const SiteOptions = () => {
  const [datai, setDatai] = useState({});
  const [themeals, setThemeals] = useState([]);
  const [uploading, setUploading] = useState(false); // Track upload state

  const loadData = async () => {
    try {
      const response = await fetch("/api/auth/homedata?page=home");
      const data = await response.json();

      if (data.length > 0) {
        setDatai(data[0]); // Set datai as the first object in the response
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMealsls = async () => {
    const res = await axios.get(`/api/auth/allmeals`);
    if (res.data) {
      setThemeals(res.data);
    }
  };

  useEffect(() => {
    fetchMealsls();
    loadData();
  }, []);

  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch the list of hero images from the backend
    const fetchHeroImages = async () => {
      try {
        const response = await axios.get("/api/auth/list-hero-images");
        setImages(response.data.images);
      } catch (error) {
        console.error("Error fetching hero images:", error);
      }
    };

    fetchHeroImages();
  }, []);

  const ImageGallery = () => {
    return (
      <div className="image-gallery">
        {images.map((image, index) => (
          <img
            key={index}
            src={`/images/heros/${image}`} // Use the correct path to the backend
            alt={`Hero ${index + 1}`}
          />
        ))}
      </div>
    );
  };

  const handleCheckboxChange = (id) => {
    let updatedMealKey = null;
    if (id === datai.meal1) {
      updatedMealKey = "meal1";
    } else if (id === datai.meal2) {
      updatedMealKey = "meal2";
    } else if (id === datai.meal3) {
      updatedMealKey = "meal3";
    }

    if (updatedMealKey) {
      setDatai((prevDatai) => ({
        ...prevDatai,
        [updatedMealKey]: null,
      }));
    } else {
      setDatai((prevDatai) => {
        if (!prevDatai.meal1) {
          return { ...prevDatai, meal1: id };
        } else if (!prevDatai.meal2) {
          return { ...prevDatai, meal2: id };
        } else if (!prevDatai.meal3) {
          return { ...prevDatai, meal3: id };
        }
        return prevDatai;
      });
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);

    try {
      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append("file", file);

      // Upload the image to the server
      const response = await axios.post("/api/auth/upload-hero", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.url) {
        // Update the `datai` state with the new image URL
        setDatai((prevDatai) => ({
          ...prevDatai,
          hero: response.data.url,
        }));

        // Optionally, save the new hero image URL to the database
        await axios.post("/api/auth/update-hero", {
          hero: response.data.url,
        });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="adminDash">
      <h1>Site Options</h1>
      <AdminPanel />
      <div>
        <h2>Hero Image</h2>
        <div key={datai._id}>
          <div className="heroSection">
            <div className="mainImg">
              <img src={datai.hero} alt="Hero" />
              <div className="upload-section">
                <input
                  type="file"
                  id="hero-upload"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                <label htmlFor="hero-upload" className="upload-button">
                  {uploading ? "Uploading..." : "Upload New Image"}
                </label>
              </div>
            </div>
            <div className="subImg">
              <ImageGallery />
            </div>
          </div>
          <div>
            <h2>Featured meals</h2>
            <div className="featuredmealContainer">
              {themeals
                .slice()
                .sort((a, b) => {
                  const isAActive =
                    a._id === datai.meal1 ||
                    a._id === datai.meal2 ||
                    a._id === datai.meal3;

                  const isBActive =
                    b._id === datai.meal1 ||
                    b._id === datai.meal2 ||
                    b._id === datai.meal3;

                  if (isAActive && !isBActive) return -1;
                  if (!isAActive && isBActive) return 1;
                  return 0;
                })
                .map((themeal) => {
                  const isActive =
                    themeal._id === datai.meal1 ||
                    themeal._id === datai.meal2 ||
                    themeal._id === datai.meal3;

                  return (
                    <div
                      key={themeal._id}
                      className={`featuredmeal ${isActive ? "active" : ""}`}
                    >
                      <input
                        type="checkbox"
                        id={themeal._id}
                        name={themeal._id}
                        checked={isActive}
                        onChange={() => handleCheckboxChange(themeal._id)}
                      />
                      <img
                        src={`/images/meals/${themeal.image}`}
                        alt={themeal.title}
                      />
                      <div>{themeal.title}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteOptions;
