import React, { createContext, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

// Create the context
export const FormContext = createContext();

// Create the provider component
export const FormProvider = ({ children }) => {
  const initialFormData = JSON.parse(localStorage.getItem("formData")) || {
    people: 0,
    nmeals: 0,
    total: 0,
    preferences: [],
    mealSelections: [],
    mealsCount: [],
    email: "",
    password: "",
    fname: "",
    lname: "",
    contact: "",
    addressLine1: "",
    addressLine2: "",
    street: "",
    town: "Dubai",
    area: "",
    deliveryDay: "",
    deliverTimeSlot: "",
    deliveryDate: "",
    specialInstructions: "",
    billingadd1: "",
    billingadd2: "",
    billingcity: "",
    billingarea: "",
    billingstreet: "",
    smImages: [],
    smallimgs: [],
    smallimgs1: [],
    smallimgs2: [],
    smallimgs3: [],
    smallimgs4: [],
    extras: [
      {
        extra: "",
        price: "",
        quantity: "",
      },
    ],
    initialPayment: 0,
    balance: 0,
    paymentHistory: [{ amount: 0 }],
    mealsByStartDate: {},
    additionalOrderData: {},
    currentStep: 0, // Add currentStep to the form data
  };

  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();
  const location = useLocation();
  const { authState } = useContext(AuthContext);
  if (authState.isAuthenticated) {
    navigate("/dashboard");
  } else {
    const stepLabels = [
      "Boxes",
      "Preferences",
      "Meals",
      "Sign Up",
      "Delivery",
      "Summary",
    ];
    const stepPaths = [
      "/boxes",
      "/preferences",
      "/meals",
      "/signup",
      "/delivery",
      "/summary",
    ];

    const isStepPath = stepPaths.includes(location.pathname);

    return (
      <FormContext.Provider value={{ formData, setFormData }}>
        {isStepPath && (
          <ul className="signupNav">
            {stepLabels.map((label, index) => (
              <li key={label}>
                {index <= formData.currentStep ? (
                  <Link to={stepPaths[index]}>{label}</Link>
                ) : (
                  <span className="disabled">{label}</span>
                )}
              </li>
            ))}
          </ul>
        )}

        {children}
      </FormContext.Provider>
    );
  }
};
