import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const { login, authState } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const { email, password } = formData;
  console.log(authState);
  useEffect(() => {
    if (authState.isAuthenticated) {
      localStorage.removeItem("formData", JSON.stringify(formData));
      navigate("/dashboard");
    }
  }, [authState.isAuthenticated, navigate, formData]);

  //localStorage.clear();

  const onChange = function (e) {
    setErrorMessage("");
    document.querySelector(".errorContainer").innerHTML = "";
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (email === "" || password === "") {
      setErrorMessage(`<p class='error'>Please fill in all fields`);
      document.querySelector(
        ".errorContainer"
      ).innerHTML = `<p class='error'>Please fill in all fields</p>`;
      return;
    }
    try {
      const response = await login(email, password);

      const handleApiResponse = (response) => {
        if (response && response.token) {
          // Use the token
          console.log("Token:", response.token);
        } else {
          console.error("Token is undefined in the response");
        }
      };

      handleApiResponse(response);

      if (response.success === false) {
        setErrorMessage(
          `<p class='error'>The email or password does not match our records. Please double-check and try again.`
        );
        document.querySelector(
          ".errorContainer"
        ).innerHTML = `<p class='error'>The email or password does not match our records. Please double-check and try again.</p>`;
      } else {
        if (response.token.user.role === "admin") {
          navigate("/adminDash");
        } else navigate("/dashboard"); // Redirect to dashboard after successful login
      }
    } catch (error) {
      console.log("Login failed", error);
      document.querySelector(".error").style.display = "block";
      setErrorMessage("Login failed");
      // Handle login error (e.g., display error message)
    }
  };

  return (
    <div className="wrapper login">
      <div className="container">
        <div className="signup">
          <div className="formContainer">
            <h1>Login</h1>
            <form onSubmit={onSubmit}>
              <input
                type="email"
                name="email"
                value={email}
                onChange={onChange}
                placeholder="Email"
              />
              <input
                type="password"
                name="password"
                value={password}
                onChange={onChange}
                placeholder="Password"
              />

              <button type="submit">Login</button>
              <div className="errorContainer"></div>
            </form>
            <div className="forgotPassword">
              <Link to="/forgotpassword">Forgot password?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
