import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Extras({ formData, setFormData, inRegister, setInRegister }) {
  const [extras, setExtras] = useState([]);
  const [keys, setKeys] = useState([]);
  const [extraCount, setExtrasCount] = useState("");

  const navigate = useNavigate();
  const meals = formData.extras;
  useEffect(() => {
    //  if(!inRegister.includes('2')){
    //       navigate('/boxes')
    //     }

    setInRegister(["0", "1", "2", "3", "7"]);
  }, []);

  useEffect(() => {
    //const data= localStorage.getItem('userdata')
  }, [formData]);

  useEffect(() => {
    const getExtras = async () => {
      try {
        const response = await axios.get(`/api/auth/extras`).then((res) => {
          const extrasData = res.data;
          setExtras(extrasData);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getExtras();
  }, []);

  const getMealCount = () => {
    return formData.mealSelections.reduce((acc, mealId) => {
      acc[mealId] = (acc[mealId] || 0) + 1;
      return acc;
    }, {});
  };

  const mealCounts = getMealCount();
  const handleSelectionAdd = (event) => {
    const buttons = document.querySelectorAll(".addMeal");

    const key = event.currentTarget.closest(".mealCard").id;
    console.log(key);
    setFormData((prevData) => ({
      ...prevData,
      extras: [
        {
          extra: extras.extra,
          price: extras.price,
          quantity: extraCount,
        },
        key,
      ],
    }));

    setKeys((prevkeys) => [key, ...prevkeys]);

    let counter = event.currentTarget
      .closest(".mealCard")
      .querySelector(".counter");
    let button = event.currentTarget
      .closest(".mealCard")
      .querySelector("button");
    counter.innerText = parseInt(counter.innerText) + 1;
    button.classList.remove("show");
    button.classList.add("hide");

    event.currentTarget.closest(".mealCard").classList.add("addactive");
  };
  const handleSelectionRemove = (event) => {};

  return (
    <div className="container">
      <h1>Sides</h1>
      <h2>Select your sides</h2>

      <div className="mealList">
        {extras.map((extra) => {
          return (
            <div key={extra._id} id={extra._id} className="mealCard">
              {/* <div className="mealContent"> */}
              <div className="imgContainer">
                <img src={`/images/extras/${extra.img}`} alt="image" />
              </div>
              <div className="contentContainer">
                <div className="mealDetails">
                  <h4>{extra.title}</h4>
                  <p>{extra.description}</p>
                  <p>{extra.price}AED</p>
                </div>

                <div className="addRemove">
                  <button onClick={handleSelectionAdd} className={`addMeal`}>
                    Add Extra
                  </button>
                  <p onClick={handleSelectionAdd}>+</p>
                  <p className="counter"></p>
                  <p onClick={handleSelectionRemove}>-</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Extras;
