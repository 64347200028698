import React, { useEffect, useState } from "react";
import "../styles/daterange.css";

function getRelevantMonday() {
  const now = new Date();
  const today = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
  );
  const dayOfWeek = today.getUTCDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

  if (dayOfWeek === 5 || dayOfWeek === 6 || dayOfWeek === 0) {
    // Calculate next Monday
    const daysUntilMonday = (8 - dayOfWeek) % 7;
    today.setUTCDate(today.getUTCDate() + daysUntilMonday);
  } else {
    // Calculate last Monday
    const daysSinceMonday = (dayOfWeek + 6) % 7;
    today.setUTCDate(today.getUTCDate() - daysSinceMonday);
  }

  return today; // Return the Date object
}

// Usage example
const relevantMonday = getRelevantMonday();
const isoDate = relevantMonday.toISOString().split("T")[0];

function getMondaysForNext52Weeks() {
  let mondays = [];
  let lastMonday = getRelevantMonday();

  for (let i = 0; i < 52; i++) {
    let currentMonday = new Date(lastMonday);
    currentMonday.setUTCDate(lastMonday.getUTCDate() + i * 7);
    mondays.push(currentMonday);
  }

  return mondays;
}

function formatDate(date) {
  const options = { day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

function getCurrentWeekIndex(mondays) {
  let today = new Date();
  const dayOfWeek = today.getUTCDay();

  if (dayOfWeek === 5 || dayOfWeek === 6 || dayOfWeek === 0) {
    const daysToAdd = (8 - dayOfWeek) % 7;
    today.setUTCDate(today.getUTCDate() + daysToAdd);
  }

  for (let i = 0; i < mondays.length; i++) {
    let nextMonday = new Date(mondays[i]);
    nextMonday.setUTCDate(mondays[i].getUTCDate() + 7);

    if (today >= mondays[i] && today < nextMonday) {
      return i;
    }
  }
  console.log("No matching week found. Returning -1.");
  return -1;
}

function getMonthsInRange(start, end) {
  let startMonth = start.toLocaleString("default", { month: "long" });
  let endMonth = end.toLocaleString("default", { month: "long" });

  if (startMonth === endMonth) {
    return startMonth;
  } else {
    return `${startMonth} - ${endMonth}`;
  }
}

const WeeklyDateRanges = ({
  onRequest,
  calRange,
  onRangeStart,
  onLastMonday,
  passedIndex,
  onSelectedIndexChange,
  isoDate,
}) => {
  const dispRange = calRange || 4;
  const [weekRanges, setWeekRanges] = useState([]);
  const [selectedIsoDate, setSelectedIsoDate] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    let mondays = getMondaysForNext52Weeks();
    let currentWeekIndex = getCurrentWeekIndex(mondays);

    if (currentWeekIndex !== -1) {
      let ranges = mondays
        .slice(currentWeekIndex, currentWeekIndex + dispRange)
        .map((monday, index) => {
          let sunday = new Date(monday);
          sunday.setUTCDate(monday.getUTCDate() + 6);

          return {
            week: currentWeekIndex + index + 1,
            start: formatDate(monday),
            end: formatDate(sunday),
            months: getMonthsInRange(monday, sunday),
            isoDate: monday.toISOString().split("T")[0],
          };
        });

      setWeekRanges(ranges);

      if (onRangeStart && ranges.length > 0) {
        onRangeStart(ranges[0].isoDate);
      }
    } else {
      setWeekRanges([]);
    }
  }, [dispRange, onRangeStart, selectedIndex]);

  useEffect(() => {
    const lastMonday = getRelevantMonday();
    if (onLastMonday && typeof onLastMonday === "function") {
      onLastMonday(lastMonday); // Pass the last Monday date to the parent
    } else {
      console.error("onLastMonday is not a function or is not defined");
    }
  }, []);

  const handleClick = (index, isoDate, event) => {
    event.stopPropagation();
    console.log("index", index, "isoDate", isoDate);
    onRequest(isoDate); // Pass the clicked date to the parent component
    onLastMonday(isoDate);
    setSelectedIndex(index, isoDate);
    setSelectedIsoDate(isoDate);
    if (onSelectedIndexChange) {
      onSelectedIndexChange(index, isoDate); // Pass the selected index to the parent
    }
  };

  return (
    <div className="dateRange">
      <ul>
        {weekRanges.map((range, index) => (
          <li
            key={range.isoDate}
            className={selectedIndex === index ? "selected" : ""}
            onClick={(event) => handleClick(index, range.isoDate, event)}
          >
            <span>
              {range.start}-
              {range.months.includes(" - ")
                ? range.months.split(" - ")[0] +
                  " - " +
                  range.end +
                  " " +
                  range.months.split("-")[1]
                : range.end + " " + range.months}
            </span>
            <br />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WeeklyDateRanges;
