import React, { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminPanel from "./adminPanel";

const OrdersByDeliveryDate = () => {
  const [orders, setOrders] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get("api/auth/prepsummary");
        setOrders(response.data.aggregatedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const downloadPDF = (deliveryDate, meals) => {
    const doc = new jsPDF();

    doc.text(`Delivery Date: ${deliveryDate}`, 10, 10);

    const tableColumns = ["Meal", "Total Servings", "Ingredients"];
    const tableRows = Object.entries(meals).map(([mealTitle, details]) => [
      mealTitle,
      details.totalServings,
      details.ingredients, // Ingredients already converted to string in the backend
    ]);

    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: 20,
      headStyles: {
        fillColor: [255, 0, 0], // Customize the color as needed
      },
    });

    doc.save(`${deliveryDate}.pdf`);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="adminDash">
      <h1>Order Summary</h1>
      <AdminPanel />
      <div className="adminMainContent">
        <div className="dashboard orderSummary">
          {Object.entries(orders).map(([deliveryDate, meals]) => (
            <div key={deliveryDate}>
              <h2>Delivery Date: {deliveryDate}</h2>
              <table>
                <thead>
                  <tr>
                    <th>Meal</th>
                    <th>Total Servings</th>
                    <th>Ingredients</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(meals).map(([mealTitle, details]) => (
                    <tr key={mealTitle}>
                      <td>{mealTitle}</td>
                      <td>{details.totalServings}</td>
                      <td>{details.ingredients}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={() => downloadPDF(deliveryDate, meals)}>
                Download PDF
              </button>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrdersByDeliveryDate;
