import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const AdminPanel = () => {
  return (
    <div className="linksPanel">
      <dl>
        <dt>
          <Link to="/adminDash">Home</Link>
        </dt>
        <dt value="Meals">Meals</dt>
        <dd>
          <Link to="/mealsList">Meals List</Link>
        </dd>
        <dd>
          <Link to="/addMeal">Add New meal</Link>
        </dd>
        <dd>
          <Link to="/setMenu">Set menus</Link>
        </dd>
        {/* <dd><Link to="/offers">Offers</Link></dd> */}
        <dt value="Users">Users</dt>
        <dd>
          <Link to="/usersList">Users List</Link>
        </dd>
        <dd>
          <Link to="/adduser">Add New Admin</Link>
        </dd>
        <dd>
          <Link to="/drivers">Drivers</Link>
        </dd>
        <dt value="Delivery">Deliveries</dt>
        <dd>
          <Link to="/deliveries">Deliveries</Link>
        </dd>
        <dd>
          <Link to="/ordersummary">Order Summary</Link>
        </dd>
        <dd>
          <Link to="/prepsummary">Preparation Summary</Link>
        </dd>
        <dd>
          <Link to="/deliverysummary">Delivery Summary</Link>
        </dd>

        {/* <dt value="Siteoptions">Site Options</dt>
        <dd>
          <Link to="/siteoptions">Site Options</Link>
        </dd> */}
      </dl>
    </div>
  );
};

export default AdminPanel;
